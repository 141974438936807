import { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import CustomButton from 'component/Button/CustomButton'
import CustomSelect from 'component/Select/CustomSelect'
import CommonTextArea from 'component/textArea/CommonTextArea'
import { BUTTON_STRING, COMMON } from 'constants/Constants'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import type { ICancelReason } from 'pages/Shifts/types'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { commonSelectValidation } from 'utils/Validations'

import { GET_REJECT_REASON, REJECT_TIME_CARD } from '../api'
import { type IRejectTimeCard } from '../types'

interface IRateProvider {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  cardId: string
  signatureUrl: string
  setIsViewTimecardOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const RejectModal = ({ setIsModalOpen, cardId, signatureUrl, setIsViewTimecardOpen }: IRateProvider) => {
  const [error, setError] = useState({
    reject_reason: '',
  })
  const [rejectReasons, setRejectReasons] = useState<ICancelReason[]>([])
  const signatureData: any = useAppSelector((state) => state?.auth?.auth)
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  useEffect(() => {
    void getRejectReasonList()
  }, [])

  const onFinish = async (values: IRejectTimeCard) => {
    const payload = {
      timecard_reject_reason: values?.reject_reason,
      timecard_reject_reason_description: values?.reason_description,
    }
    dispatch(setGlobalLoader(true))
    try {
      const response = await REJECT_TIME_CARD(cardId, payload)
      if (response?.data?.statusCode === 1) {
        setIsModalOpen(false)
        setIsViewTimecardOpen(true)
        void message.success(response?.data?.message)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error) {}
    dispatch(setGlobalLoader(false))
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const getRejectReasonList = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_REJECT_REASON()
      if (response?.data?.statusCode === 1) {
        setRejectReasons(response?.data?.data)
      } else {
        void message.error(response?.data?.message[0])
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <Form
      className="cancel-container flex flex-col items-start justify-start w-full h-full p-8"
      form={form}
      onFinish={onFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <div className="flex flex-col w-full relative">
        <CustomSelect
          label={COMMON.SELECT_REASON}
          options={rejectReasons?.map((item: { reason: string; id: string }) => ({
            label: item?.reason,
            value: item?.id,
          }))}
          name="reject_reason"
          error={error.reject_reason}
          rules={commonSelectValidation('reject reason')}
        />
      </div>
      <CommonTextArea name="reason_description" placeholder={COMMON.ADDITIONAL_DETAIL} rows={4} />
      <div className="flex gap-4 w-full items-end justify-end ">
        <CustomButton type="default" text={BUTTON_STRING.BACK} className="w-160 h-49" onClick={handleCancel} />
        <CustomButton text={BUTTON_STRING.REJECT} type="text" htmlType="submit" className="w-160 h-49 cancel-button" />
      </div>
    </Form>
  )
}

export default RejectModal
