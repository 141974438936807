import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Image, message } from 'antd'
import { facilityProfile } from 'assets/imgs/index'
import { CameraIcon, Edit } from 'assets/svgs/index'
import CustomButton from 'component/Button/CustomButton'
import InputField from 'component/InputField/InputField'
import CustomSelect from 'component/Select/CustomSelect'
import UploadMedia from 'component/UploadMedia/UploadMedia'
import { BUTTON_STRING, MAX_FILE_SIZE_MB } from 'constants/Constants'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { mediaUpload } from 'pages/Welcome/api'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData, type IProfile } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { validateFileSize } from 'utils/utils'
import { commonInputFieldValidation, commonSelectValidation, emailValidations, mobileValidations } from 'utils/Validations'

const DetailDataOne = [
  {
    id: 1,
    name: 'Contact Info',
    data: [
      {
        id: 1,
        head: 'CONTACT NO.',
        sub: '+1 123 456 7890',
      },
      {
        id: 2,
        head: 'EMAIL',
        sub: 'heberhealth@gmail.com',
      },
      {
        id: 3,
        head: 'ADDRESS',
        sub: '3825 Brownley DrMacon, GA 31206',
      },
      {
        id: 4,
        head: 'WEBSITE',
        sub: 'www.rockycare.com',
      },
    ],
  },
  {
    id: 2,
    name: 'Infrastructure',
    data: [
      {
        id: 1,
        head: 'TYPE',
        sub: 'Facility',
      },
      {
        id: 2,
        head: 'NUMBER OF BEDS',
        sub: '120',
      },
      {
        id: 3,
        head: 'FACILITY TYPE',
        sub: 'Nursing Home',
      },
    ],
  },
]

const DetailData = [
  {
    id: 1,
    name: 'General Instructions',
    data: [
      {
        id: 1,
        head: 'CONTACT NO.',
        sub: '+1 123 456 7890',
      },
      {
        id: 2,
        head: 'EMAIL',
        sub: 'heberhealth@gmail.com',
      },
      {
        id: 3,
        head: 'ADDRESS',
        sub: '3825 Brownley DrMacon, GA 31206',
      },
      {
        id: 4,
        head: 'WEBSITE',
        sub: 'www.rockycare.com',
      },
      {
        id: 5,
        head: 'TYPE',
        sub: 'Facility',
      },
      {
        id: 6,
        head: 'NUMBER OF BEDS',
        sub: '120',
      },
      {
        id: 7,
        head: 'FACILITY TYPE',
        sub: 'Nursing Home',
      },
    ],
  },
]

const Profile = () => {
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageLink, setImageLink] = useState<string>('')

  const [error, setError] = useState({
    image: '',
    address: '',
    apartment: '',
    zip_code: '',
    mobile_no: '',
    city: '',
    email: '',
    state: '',
    country: '',
    orientation: '',
    shift_description: '',
    breaks: '',
    timekeeping: '',
    no_beds: '',
    facility_type: '',
  })

  const [form] = Form.useForm()

  const handleMediaUpload = async (data: string | Blob) => {
    dispatch(setGlobalLoader(true))
    try {
      const formData = new FormData()
      formData.append('image', data)
      formData.append('folder', 'facility')
      const response = await mediaUpload(formData)
      if (response?.data?.statusCode === 1) {
        void message.success(response?.data?.message)
        setImageLink(response?.data?.data?.image)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error) {
      void message.error('Failed to upload image. Please try again.')
    }
    dispatch(setGlobalLoader(false))
  }

  const handleMediaChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      if (!file.type.startsWith('image/')) {
        setError((prev) => {
          return { ...prev, image: VALIDATION_ERROR.IMAGE.ONLYIMG }
        })
        return
      }

      const validSize = validateFileSize(file, MAX_FILE_SIZE_MB)
      if (!validSize) {
        setError({ ...error, image: VALIDATION_ERROR.IMAGE.ONLYIMG })
        return
      }

      void handleMediaUpload(file)
    }
  }

  const onFinish = (values: IProfile) => {
    setIsEdit(false)
  }

  const State = [
    {
      label: 'California',
      value: 'California',
    },
  ]

  const Country = [
    {
      label: 'USA',
      value: 'USA',
    },
  ]

  const FacilityType = [
    {
      label: 'Nursing Home',
      value: 'Nursing Home',
    },
  ]

  return (
    <div className="profile-wrapper flex flex-col items-center w-full">
      <Form
        form={form}
        onFinish={onFinish}
        className="flex flex-col justify-center items-start w-full relative gap-16 "
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
      >
        <div className="flex justify-start items-center gap-5 ">
          {isEdit ? (
            <Form.Item name="image">
              <UploadMedia
                className="flex justify-center items-center"
                icon={<CameraIcon />}
                imgHeight={200}
                imgWidth={200}
                imageUrl={imageLink}
                baseUrl={imageLink}
                edit={isEdit}
                applyOverlay={true}
                styleImgClass="upload-profile-img my-facility-profile"
                handleChange={(e) => {
                  void handleMediaChange(e)
                }}
              />
            </Form.Item>
          ) : (
            <Image preview={false} src={facilityProfile} alt="Profile Picture" className="profile-pic-image" />
          )}
          <p className="mobile-heading-h3 font-medium text-neutral-800 user-text">Rocky Mtn. Care of Heber</p>
        </div>
        <div className="absolute right-0 top-0">
          {isEdit ? (
            <CustomButton text={BUTTON_STRING.SAVE_CHANGES} type="default" htmlType="submit" />
          ) : (
            <CustomButton
              isIcon={true}
              className="user-icon"
              svgIcon={<Edit className="svg" />}
              text={BUTTON_STRING.EDIT_profile}
              type="default"
              onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                e.preventDefault()
                setIsEdit(true)
              }}
            />
          )}
        </div>
        {!isEdit ? (
          <div className="w-full grid grid-col-2 justify-center items-start gap-10">
            <div className="flex flex-col items-center gap-10">
              {DetailDataOne.map((data) => {
                return (
                  <div className="flex flex-col items-start rounded-4 border-stroke bg-white overflow-hidden self-stretch box-shadow" key={data.id}>
                    <div className="flex py-2 px-4 items-center gap-6 self-stretch bg-primary-50">
                      <p className="text-neutral-800 mobile-heading-h5 font-medium">{data.name}</p>
                    </div>
                    <div className="flex p-4 flex-col items-start gap-4 rounded-4">
                      {data.data.map((Item) => {
                        return (
                          <div className="flex flex-col items-start gap-2 self-stretch" key={Item.id}>
                            <p className="text-neutral-500 para-p2 font-medium">{Item.head}</p>
                            <p className="text-neutral-800 para-p1 font-medium">{Item.sub}</p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="flex flex-col items-center gap-10">
              {DetailData.map((data) => {
                return (
                  <div className="flex flex-col items-start rounded-4 border-stroke bg-white overflow-hidden self-stretch box-shadow   min-h-717" key={data.id}>
                    <div className="flex py-2 px-4 items-center gap-6 self-stretch bg-primary-50">
                      <p className="text-neutral-800 mobile-heading-h5 font-medium">{data.name}</p>
                    </div>
                    <div className="flex p-4 flex-col items-start gap-4 rounded-4">
                      {data.data.map((item) => {
                        return (
                          <div className="flex flex-col items-start gap-2 self-stretch" key={item.id}>
                            <p className="text-neutral-500 para-p2 font-medium">{item.head}</p>
                            <p className="text-neutral-800 para-p1 font-medium">{item.sub}</p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <>
            <div className="edit-my-profile-container w-full flex items-start gap-16">
              <div className="flex w-full flex-col items-start gap-6">
                <p className="text-neutral-800 mobile-heading-h5 font-medium">Contact Info</p>
                <div className="flex flex-col items-start self-stretch">
                  <InputField name="email" placeholder="email" rules={emailValidations} error={error.email} />
                  <InputField name="mobile_no" placeholder="Contact No." rules={mobileValidations} error={error.mobile_no} />
                  <InputField name="address" placeholder="Street Address" rules={commonInputFieldValidation('street Address')} error={error.address} />
                  <InputField name="apartment" placeholder="Apartment/Building" rules={commonInputFieldValidation('apartment name')} error={error.apartment} />
                  <InputField name="zip_code" placeholder="Zip / Postal Code" rules={commonInputFieldValidation('zip code')} error={error.zip_code} />
                  <InputField name="city" placeholder="Town/City" rules={commonInputFieldValidation('city name')} error={error.city} />
                  <CustomSelect label="State" options={State} name="state" rules={commonSelectValidation('state')} error={error.state} />
                  <CustomSelect label="country" options={Country} name="country" rules={commonSelectValidation('country')} error={error.country} />
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-6">
                <p className="text-neutral-800 mobile-heading-h5 font-medium">General Instrctions</p>
                <div className="flex flex-col items-start self-stretch">
                  <InputField name="shift" placeholder="First Shift" defaultValue="-" />
                  <InputField name="orientation" placeholder="Orientation" rules={commonInputFieldValidation('Orientation')} error={error.orientation} />
                  <InputField name="shift_description" placeholder="Shift Description" rules={commonInputFieldValidation('shift description')} error={error.shift_description} />
                  <InputField name="breaks" placeholder="Lunch /Breaks" rules={commonInputFieldValidation('lunch/break time')} error={error.breaks} />
                  <InputField name="dress_code" placeholder="Dress Code" defaultValue="-" />
                  <InputField name="parking" placeholder="Parking Instructions" defaultValue="-" />
                  <InputField name="door_locks" placeholder="Door Locks" defaultValue="-" />
                  <InputField name="timekeeping" placeholder="Timekeeping" rules={commonInputFieldValidation('timekeeping name')} error={error.timekeeping} />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start gap-6 self-stretch">
              <p className="text-neutral-800 font-medium mobile-heading-h5">Infrastructure</p>
              <div className="flex flex-col items-center gap-6 self-stretch ">
                <div className="flex flex-col justify-center self-stretch items-start">
                  <div className="grid grid-col-2 justify-center items-start gap-16 w-full self-stretch profile-content-box">
                    <div>
                      <InputField name="no_beds" placeholder="Number of Beds" rules={commonInputFieldValidation('no of beds')} error={error.no_beds} />
                    </div>
                    <div>
                      <CustomSelect label="Facility Type" options={FacilityType} name="facility_type" rules={commonSelectValidation('facility type')} error={error.facility_type} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  )
}

export default Profile
