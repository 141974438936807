export const loadGoogleMapsScript = async (apiKey: string): Promise<void> => {
  await new Promise<void>((resolve, reject) => {
    if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
      resolve() // Google Maps is already loaded
      return
    }

    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places` // Ensure you include the required libraries
    script.async = true
    script.defer = true
    script.onload = () => {
      resolve()
    }
    script.onerror = (error) => {
      reject(error)
    }
    document.head.appendChild(script)
  })
}
