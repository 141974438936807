import { useEffect, useRef, useState } from 'react'
import { profile } from 'assets/imgs/index'
import { CloseIcon, EditPenIcon, RefreshIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import { BUTTON_STRING, SHIFTS } from 'constants/Constants'
import { SHIFT_STATUS } from 'constants/ConstantStatus'
import { loadGoogleMapsScript } from 'service/GogleServices'
import useSocket from 'socket/useSocket'
import { formatTitle } from 'utils/helper'

const svgIconUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
    <circle cx="24" cy="24" r="24" fill="url(#paint0_linear_7294_244590)" />
    <path
      d="M33.5 18.5H30.5V14.5C30.5 13.9 30.1 13.5 29.5 13.5H18.5C17.9 13.5 17.5 13.9 17.5 14.5V18.5H14.5C13.9 18.5 13.5 18.9 13.5 19.5V33.5C13.5 34.1 13.9 34.5 14.5 34.5H33.5C34.1 34.5 34.5 34.1 34.5 33.5V19.5C34.5 18.9 34.1 18.5 33.5 18.5ZM19.5 30.5H18.5C17.9 30.5 17.5 30.1 17.5 29.5C17.5 28.9 17.9 28.5 18.5 28.5H19.5C20.1 28.5 20.5 28.9 20.5 29.5C20.5 30.1 20.1 30.5 19.5 30.5ZM19.5 26.5H18.5C17.9 26.5 17.5 26.1 17.5 25.5C17.5 24.9 17.9 24.5 18.5 24.5H19.5C20.1 24.5 20.5 24.9 20.5 25.5C20.5 26.1 20.1 26.5 19.5 26.5ZM24.5 30.5H23.5C22.9 30.5 22.5 30.1 22.5 29.5C22.5 28.9 22.9 28.5 23.5 28.5H24.5C25.1 28.5 25.5 28.9 25.5 29.5C25.5 30.1 25.1 30.5 24.5 30.5ZM24.5 26.5H23.5C22.9 26.5 22.5 26.1 22.5 25.5C22.5 24.9 22.9 24.5 23.5 24.5H24.5C25.1 24.5 25.5 24.9 25.5 25.5C25.5 26.1 25.1 26.5 24.5 26.5ZM25.5 21H25V21.5C25 22.1 24.6 22.5 24 22.5C23.4 22.5 23 22.1 23 21.5V21H22.5C21.9 21 21.5 20.6 21.5 20C21.5 19.4 21.9 19 22.5 19H23V18.5C23 17.9 23.4 17.5 24 17.5C24.6 17.5 25 17.9 25 18.5V19H25.5C26.1 19 26.5 19.4 26.5 20C26.5 20.6 26.1 21 25.5 21ZM29.5 30.5H28.5C27.9 30.5 27.5 30.1 27.5 29.5C27.5 28.9 27.9 28.5 28.5 28.5H29.5C30.1 28.5 30.5 28.9 30.5 29.5C30.5 30.1 30.1 30.5 29.5 30.5ZM29.5 26.5H28.5C27.9 26.5 27.5 26.1 27.5 25.5C27.5 24.9 27.9 24.5 28.5 24.5H29.5C30.1 24.5 30.5 24.9 30.5 25.5C30.5 26.1 30.1 26.5 29.5 26.5Z"
      fill="white" />
      <defs>
        <linearGradient id="paint0_linear_7294_244590" x1="-8.4" y1="-8.4" x2="40.8" y2="51" gradientUnits="userSpaceOnUse">
          <stop stop-color="#220EB1"/>
          <stop offset="1" stop-color="#6111B9"/>
        </linearGradient>
      </defs>
    </svg>
`)}`

const DynamicImageIcon = (imageUrl: string) => {
  return (
    <div
      style={{
        width: '48px',
        height: '48px',
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover', // ensures the image covers the container
        backgroundPosition: 'center', // ensures the image is centered
        borderRadius: '50%', // keeps it as a circle like in the SVG
      }}
    ></div>
  )
}

// const profileMarkerUrl = (imageUrl: string) => {
//   return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
//     <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
//       <defs>
//         <clipPath id="circleView">
//           <circle cx="30" cy="30" r="28" />
//         </clipPath>
//       </defs>
//       <circle cx="30" cy="30" r="30" fill="white" stroke="#fff" stroke-width="2"/>
//       <g clip-path="url(#circleView)">
//         <image x="0" y="0" width="60" height="60" href="${imageUrl}" />
//       </g>
//       <circle cx="30" cy="30" r="28" fill="none" stroke="#fff" stroke-width="2"/>
//     </svg>
//   `)}`
// }

const ProviderTracking = ({
  provider,
  tabId,
  setIsEdit,
  isActivity,
  radius,
  facility,
  status,
  setTabId,
  setIsCancelModal,
  setIsDetailShiftOpen,
  setIsCreateShiftModalOpen,
}: any) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [location, setLocation] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  })
  const [facilityLocation, setFacilityLocation] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  })
  const [timeDistance, setTimeDistance] = useState<string>('0 mins')
  const socket = useSocket()
  const yardsToMeters = (yards: number) => yards * 0.9144
  const radiusInMeters = yardsToMeters(radius)

  useEffect(() => {
    if (facility?.latitude && facility?.longitude) {
      setFacilityLocation({
        lat: parseFloat(facility.latitude),
        lng: parseFloat(facility.longitude),
      })
    }
  }, [facility])

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: 'AIzaSyBvDH8rSB8TWL19eIb1-IqeuMr9iJo6H8k',
  // })
  // const socket = useSocket()

  useEffect(() => {
    if ([SHIFT_STATUS.SCHEDULE, SHIFT_STATUS.RUNNING_LATE].includes(status)) providerTrackingDetails()
  }, [tabId, status])

  const providerTrackingDetails = () => {
    socket?.on(`provider-tracking/${provider?.id as string}`, (data: { latitude: string; longitude: string }) => {
      setLocation({
        lat: parseFloat(data?.latitude),
        lng: parseFloat(data?.longitude),
      })
      return true
    })
    setLocation({
      lat: parseFloat(provider?.latitude),
      lng: parseFloat(provider?.longitude),
    })
    return true
  }

  // useEffect(() => {
  //   const getDistanceMatrix = () => {
  //     const service = new google.maps.DistanceMatrixService()

  //     // Use location and facilityLocation as both origins and destinations
  //     const origin1 = location // Current user location
  //     const destination1 = facilityLocation // Facility location

  //     // Build request
  //     const request = {
  //       origins: [origin1],
  //       destinations: [destination1],
  //       travelMode: google.maps.TravelMode.DRIVING,
  //       unitSystem: google.maps.UnitSystem.METRIC,
  //       avoidHighways: false,
  //       avoidTolls: false,
  //     }

  //     // Make the Distance Matrix API call
  //     void service.getDistanceMatrix(request).then((response) => {
  //       const result = response
  //       const timeDifference = result?.rows[0]?.elements[0]?.duration?.text
  //       setTimeDistance(timeDifference)
  //     })
  //   }

  //   if (window.google) {
  //     getDistanceMatrix()
  //   } else {
  //     // Load the Google Maps API dynamically if not loaded
  //     const script = document.createElement('script')
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBvDH8rSB8TWL19eIb1-IqeuMr9iJo6H8k&libraries=places`
  //     script.async = true
  //     script.onload = () => {
  //       getDistanceMatrix()
  //     }
  //     document.body.appendChild(script)
  //   }
  // }, [location, facilityLocation])

  useEffect(() => {
    const getDistanceMatrix = (retryCount = 2) => {
      const service = new google.maps.DistanceMatrixService()

      // Use location and facilityLocation as both origins and destinations
      const origin1 = location // Current user location
      const destination1 = facilityLocation // Facility location

      // Build request
      const request = {
        origins: [origin1],
        destinations: [destination1],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      }

      // Make the Distance Matrix API call
      service
        .getDistanceMatrix(request)
        .then((response) => {
          const result = response
          const timeDifference = result?.rows[0]?.elements[0]?.duration?.text

          if (timeDifference) {
            setTimeDistance(timeDifference)
          } else if (retryCount > 0) {
            console.log(`Retrying... attempts left: ${retryCount}`)
            getDistanceMatrix(retryCount - 1) // Recursive call with decreased retry count
          } else {
            console.error('Failed to get distance after several retries')
          }
        })
        .catch((error) => {
          if (retryCount > 0) {
            console.log(`Error occurred, retrying... attempts left: ${retryCount}`)
            getDistanceMatrix(retryCount - 1) // Recursive call with decreased retry count
          } else {
            console.error('Failed to get distance after several retries', error)
          }
        })
    }

    if (window.google) {
      getDistanceMatrix()
    } else {
      // Load the Google Maps API dynamically if not loaded
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}&libraries=places`
      script.async = true
      script.onload = () => {
        getDistanceMatrix()
      }
      document.body.appendChild(script)
    }
  }, [location, facilityLocation])

  useEffect(() => {
    // Load the Google Maps script dynamically
    loadGoogleMapsScript(process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string)
      .then(async () => {
        const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary

        if (mapContainerRef.current) {
          // Initialize the map with a valid Map ID
          const mapInstance = new window.google.maps.Map(mapContainerRef.current, {
            center: { lat: facilityLocation?.lat, lng: facilityLocation?.lng }, // Initial center, but will be adjusted
            zoom: 12, // Initial zoom level, but will be adjusted
            mapId: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Use a valid Map ID
            mapTypeControl: false,
            streetViewControl: false,
          })

          setMap(mapInstance) // Save map instance

          // Create LatLngBounds to include both markers
          const bounds = new window.google.maps.LatLngBounds()

          // Add the first custom SVG marker using AdvancedMarkerElement
          const firstMarker: any = new AdvancedMarkerElement({
            position: { lat: facilityLocation?.lat, lng: facilityLocation?.lng }, // Marker position
            map: mapInstance, // Assign the map to the marker
            title: 'Facility', // Tooltip title
            content: document.createElement('div'), // Add any HTML content for the marker
          })
          firstMarker.content.innerHTML = `<img src="${svgIconUrl}" width="40" height="40" alt="Marker" />`

          // Extend the bounds to include the first marker
          bounds.extend(new window.google.maps.LatLng(facilityLocation?.lat, facilityLocation?.lng))

          // Add the radius circle around the first marker
          const circle = new window.google.maps.Circle({
            map: mapInstance,
            radius: radiusInMeters, // Radius in meters
            fillColor: '#8A2BE2', // Fill color (adjust as needed)
            fillOpacity: 0.3, // Opacity of the filled circle
            strokeColor: '#4B0082', // Border color (adjust as needed)
            strokeOpacity: 0.8, // Opacity of the border
            strokeWeight: 2, // Border thickness
            center: firstMarker.position, // Center the circle at the first marker's position
          })

          if (status === SHIFT_STATUS.RUNNING_LATE || status === SHIFT_STATUS.SCHEDULE) {
            const imageUrl = provider?.base_url && provider?.profile_image ? `${provider.base_url as string}${provider.profile_image as string}` : profile
            // Add the second custom marker using the image from the provided URL
            const secondMarker: any = new AdvancedMarkerElement({
              position: { lat: location?.lat, lng: location?.lng }, // Adjust the position of the second marker
              map: mapInstance, // Assign the map to the marker
              title: 'Custom Image Marker', // Tooltip title
              content: document.createElement('div'), // Add any HTML content for the marker
            })
            secondMarker.content.innerHTML = `<img src=${imageUrl} width="50" height="50" alt="Marker" style="border-radius: 50%; border: 2px solid #fff; background: #fff" />`

            // Extend the bounds to include the second marker
            bounds.extend(new window.google.maps.LatLng(location?.lat, location?.lng))
          }
          // Adjust the map to fit both markers
          mapInstance.fitBounds(bounds)
        }
      })
      .catch((error) => {
        console.error('Failed to load Google Maps script:', error)
      })
  }, [facilityLocation, location, status])

  useEffect(() => {
    if (!socket) return

    const onConnect = () => {
      console.log('Connection established:', new Date().toISOString())
    }

    const onDisconnect = () => {
      console.log('Disconnected')
    }

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)

    // Cleanup function to remove event listeners
    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
    }
  }, [socket])

  // const onUnmount = useCallback(function callback(map: any) {
  //   setMap(null)
  // }, [])

  // const onLoad = useCallback(
  //   (map: google.maps.Map) => {
  //     const bounds = new window.google.maps.LatLngBounds()

  //     if (status === SHIFT_STATUS.RUNNING_LATE || status === SHIFT_STATUS.SCHEDULE) {
  //       // Add facility location to bounds
  //       bounds.extend(new window.google.maps.LatLng(facilityLocation.lat, facilityLocation.lng))

  //       // Add provider location to bounds
  //       bounds.extend(new window.google.maps.LatLng(location.lat, location.lng))
  //     } else {
  //       // Add only facility location to bounds
  //       bounds.extend(new window.google.maps.LatLng(facilityLocation.lat, facilityLocation.lng))
  //     }

  //     // Adjust the map to fit bounds
  //     map.fitBounds(bounds)

  //     // Set the map instance
  //     setMap(map)
  //   },
  //   [facilityLocation, location, status] // Make sure all dependencies are included here
  // )

  const handleRepostReopen = () => {
    setIsDetailShiftOpen(false)
    setIsCreateShiftModalOpen(true)
  }

  const handleReopen = () => {
    console.log('first')
  }

  const handleEditClick = () => {
    setTabId('1')
    setIsEdit(true)
  }

  // const onLoad = useCallback(
  //   (map: google.maps.Map) => {
  //     const bounds = new window.google.maps.LatLngBounds()

  //     if (status === SHIFT_STATUS.RUNNING_LATE || status === SHIFT_STATUS.SCHEDULE) {
  //       // Add facility location to bounds
  //       bounds.extend(new window.google.maps.LatLng(facilityLocation.lat, facilityLocation.lng))

  //       // Add provider location to bounds
  //       bounds.extend(new window.google.maps.LatLng(location.lat, location.lng))
  //     } else {
  //       // Add only facility location to bounds
  //       bounds.extend(new window.google.maps.LatLng(facilityLocation.lat, facilityLocation.lng))
  //     }

  //     // Adjust the map to fit bounds
  //     map.fitBounds(bounds)

  //     // Set the map instance
  //     setMap(map)
  //   },
  //   [facilityLocation, location, status] // Make sure all dependencies are included here
  // )
  // const mapOptions = {
  //   mapTypeControl: false,
  //   styles: [
  //     {
  //       featureType: 'all',
  //       elementType: 'all',
  //       stylers: [{ saturation: -80 }, { lightness: 20 }],
  //     },
  //     {
  //       featureType: 'road',
  //       elementType: 'geometry',
  //       stylers: [{ lightness: 50 }, { visibility: 'simplified' }],
  //     },
  //     {
  //       featureType: 'road',
  //       elementType: 'labels',
  //       stylers: [{ visibility: 'on' }],
  //     },
  //     {
  //       elementType: 'geometry',
  //       stylers: [{ color: '#f5f5f5' }],
  //     },
  //     {
  //       elementType: 'labels.icon',
  //       stylers: [{ visibility: 'off' }],
  //     },
  //     {
  //       elementType: 'labels.text.fill',
  //       stylers: [{ color: '#616161' }],
  //     },
  //     {
  //       elementType: 'labels.text.stroke',
  //       stylers: [{ color: '#f5f5f5' }],
  //     },
  //     {
  //       featureType: 'administrative.land_parcel',
  //       elementType: 'labels.text.fill',
  //       stylers: [{ color: '#bdbdbd' }],
  //     },
  //     {
  //       featureType: 'poi',
  //       elementType: 'geometry',
  //       stylers: [{ color: '#eeeeee' }],
  //     },
  //     {
  //       featureType: 'poi',
  //       elementType: 'labels.text.fill',
  //       stylers: [{ color: '#757575' }],
  //     },
  //     {
  //       featureType: 'poi.park',
  //       elementType: 'geometry',
  //       stylers: [{ color: '#e5e5e5' }],
  //     },
  //     {
  //       featureType: 'poi.park',
  //       elementType: 'labels.text.fill',
  //       stylers: [{ color: '#9e9e9e' }],
  //     },
  //     {
  //       featureType: 'road',
  //       elementType: 'geometry',
  //       stylers: [{ color: '#ffffff' }],
  //     },
  //     {
  //       featureType: 'road.arterial',
  //       elementType: 'labels.text.fill',
  //       stylers: [{ color: '#757575' }],
  //     },
  //     {
  //       featureType: 'road.highway',
  //       elementType: 'geometry',
  //       stylers: [{ color: '#dadada' }],
  //     },
  //     {
  //       featureType: 'road.highway',
  //       elementType: 'labels.text.fill',
  //       stylers: [{ color: '#616161' }],
  //     },
  //     {
  //       featureType: 'road.local',
  //       elementType: 'labels.text.fill',
  //       stylers: [{ color: '#9e9e9e' }],
  //     },
  //     {
  //       featureType: 'transit.line',
  //       elementType: 'geometry',
  //       stylers: [{ color: '#e5e5e5' }],
  //     },
  //     {
  //       featureType: 'transit.station',
  //       elementType: 'geometry',
  //       stylers: [{ color: '#eeeeee' }],
  //     },
  //     {
  //       featureType: 'water',
  //       elementType: 'geometry',
  //       stylers: [{ color: '#c9c9c9' }],
  //     },
  //     {
  //       featureType: 'water',
  //       elementType: 'labels.text.fill',
  //       stylers: [{ color: '#9e9e9e' }],
  //     },
  //   ],
  //   zoom: status === SHIFT_STATUS.SCHEDULE || status === SHIFT_STATUS.RUNNING_LATE ? 10 : 60,
  //   zoomControl: true,
  //   streetViewControl: false,
  //   fullscreenControl: true,
  // }

  return (
    <div className="w-full h-full">
      <div className="m-6 block relative" style={{ width: '100%', height: 'calc(90vh - 200px)' }}>
        <p className="mobile-heading-h5 text-neutral-800 pb-4">Provider Tracking</p>
        <div ref={mapContainerRef} style={{ height: 'calc(100% - 32px)', width: 'calc(100% - 48px)' }} />
        <div className="absolute p-4 bg-white bottom-16 left-16 rounded-3 min-w-168">
          <p className="para-p3 font-medium text-neutral-800 text-center">
            {status === SHIFT_STATUS.RUNNING_LATE || status === SHIFT_STATUS.SCHEDULE ? SHIFTS.PROVIDER_ARRIVING : SHIFTS.SHIFT_IS}
          </p>
          <p className="para-p2 font-medium text-neutral-800 text-center">
            {status === SHIFT_STATUS.RUNNING_LATE || status === SHIFT_STATUS.SCHEDULE ? timeDistance : formatTitle(status)}
          </p>
        </div>
        {/* {isLoaded ? (
          <>
            <GoogleMap
              mapContainerStyle={containerStyle as any}
              center={facilityLocation}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{ ...mapOptions, styles: mapStyle, disableDefaultUI: true }}
            >
              <CircleF
                center={facilityLocation}
                radius={radiusInMeters}
                options={{
                  strokeColor: '#220EB1',
                  strokeOpacity: 0.9,
                  strokeWeight: 2,
                  fillColor: '#220EB1',
                  fillOpacity: 0.1,
                  clickable: true,
                  zIndex: 1,
                }}
              />
              <MarkerF
                position={facilityLocation}
                icon={{
                  url: svgIconUrl,
                  scaledSize: new window.google.maps.Size(48, 48),
                }}
                zIndex={2}
              />

              {status === SHIFT_STATUS.RUNNING_LATE ||
                (status === SHIFT_STATUS.SCHEDULE && (
                  <MarkerF
                    position={location}
                    icon={{
                      // url: `${provider?.base_url as string}/${provider?.profile_image as string}` || profile,
                      url: `${provider?.base_url as string}${provider?.profile_image as string}`,
                      // url: generateMarkerSvg('https://nurses-now.s3.amazonaws.com/provider/9d3a6316-d899-46e8-9d77-017942fda9db.jpg'),
                      scaledSize: new window.google.maps.Size(60, 60),
                      // anchor: new window.google.maps.Point(30, 30), // Centers the marker
                    }}
                    // zIndex={2}
                  />
                ))}

              <div className="absolute p-4 bg-white bottom-16 left-16 rounded-3">
                <p className="para-p3 font-medium text-neutral-800">{SHIFTS.PROVIDER_ARRIVING}</p>
                <p className="para-p2 font-medium text-neutral-800 text-center">{timeDistance}</p>
              </div>
            </GoogleMap>
          </>
        ) : (
          <></>
        )} */}
      </div>
      <div
        className={`${status === SHIFT_STATUS.UNSUBMITTED || status === SHIFT_STATUS.ON_GOING || status === SHIFT_STATUS.VOID ? '' : 'flex py-4 px-6 justify-end items-center self-stretch border-t-stroke'}`}
      >
        <div className="flex gap-4">
          {status === SHIFT_STATUS.OPEN || status === SHIFT_STATUS.SCHEDULE ? (
            <>
              <CustomButton
                isIcon
                onClick={() => {
                  setIsCancelModal(true)
                }}
                svgIcon={<CloseIcon fill="#D1293D" />}
                isDanger={true}
                className="w-160 user-icon danger-btn"
                type="default"
                text={BUTTON_STRING.CANCEL_SHIFT}
              />
              <CustomButton isIcon svgIcon={<EditPenIcon fill="#fff" />} text={BUTTON_STRING.EDIT} type="primary" htmlType="button" className="w-160" onClick={handleEditClick} />
            </>
          ) : status === SHIFT_STATUS.CANCELLED || status === SHIFT_STATUS.COMPLETED ? (
            <CustomButton
              isIcon
              svgIcon={<RefreshIcon className="svg" />}
              type="default"
              text={status === SHIFT_STATUS.CANCELLED ? BUTTON_STRING.RE_OPEN : BUTTON_STRING.RE_POST}
              className="w-160 user-icon "
              onClick={status === SHIFT_STATUS.CANCELLED ? handleReopen : handleRepostReopen}
            />
          ) : status === SHIFT_STATUS.UNSUBMITTED || status === SHIFT_STATUS.ON_GOING || status === SHIFT_STATUS.VOID ? null : (
            <CustomButton
              onClick={() => {
                setIsCancelModal(true)
              }}
              isIcon
              svgIcon={<CloseIcon />}
              type="default"
              text={BUTTON_STRING.CANCEL_SHIFT}
              className="w-160"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ProviderTracking
