import React, { type ReactNode, useEffect, useRef, useState } from 'react'
import { DatePicker, Form } from 'antd'
import { SelectDownIcon } from 'assets/svgs'
import dayjs from 'dayjs'

import './CustomDatePicker.scss'

interface IRules {
  required?: boolean
  message?: string
  validator?: (rule: any, value: any) => Promise<void>
}

interface IDatePicker {
  name: string
  format?: string
  rules?: IRules[]
  placeholder?: string
  icon?: ReactNode
  inputClass?: string
  labelClass?: string
  textClass?: string
  mainClass?: string
  wrapperClass?: string
  error?: string | boolean
  disabled?: boolean
  disablePastDates?: (current: any) => boolean
  form?: any
  value?: any
  reset?: boolean
  onChange?: (e: any) => void
}

const CustomDatePickerField = ({ ...props }: IDatePicker) => {
  const { wrapperClass, mainClass, inputClass, labelClass, textClass, error, placeholder, rules, name, icon = '', format, disablePastDates, form, value, disabled, reset } = props

  const [focused, setFocused] = useState(false)
  const [hasValue, setValue] = useState(false)
  const dateRef = useRef<any>(null)

  useEffect(() => {
    const initialValue = dateRef.current?.nativeElement?.children[0]?.children[0]?.value
    const isValidDate = initialValue ? initialValue !== '' : false
    setFocused(isValidDate)
    setValue(form.getFieldValue(name))
  }, [dateRef])

  useEffect(() => {
    if (reset) {
      setFocused(false)
      setValue(false)
    }
  }, [reset])

  useEffect(() => {
    if (value) {
      setFocused(true)
      setValue(true)
    } else {
      setFocused(false)
      setValue(false)
    }
  }, [value])

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    if (dateRef.current?.nativeElement?.children[0]?.children[0]?.value) {
      setValue(true)
      setFocused(true)
    } else {
      setValue(false)
      setFocused(false)
    }
  }

  const handleChange = (date: any) => {
    const isValidDate = dayjs(date)?.isValid()
    setValue(isValidDate)
    setFocused(isValidDate)
  }

  return (
    <div className={`${mainClass as string}`}>
      <div className={`${wrapperClass as string} datePicker-wrapper ${error ? '' : 'mb-6'} `}>
        <div className={`floatingLabelInput ${inputClass as string}`}>
          {icon !== '' && <div className={`icon-container ${disabled ? 'icon-disabled' : ''}`}>{icon}</div>}
          <label
            htmlFor={name}
            className={`label-float ${focused || hasValue ? 'actives' : ''} ${labelClass as string} ${icon !== '' ? 'left-50' : ''} ${disabled ? 'text-neutral-300' : ''}`}
          >
            {placeholder}
          </label>
          <Form.Item name={name} rules={rules} className=" datePicker-content">
            <DatePicker
              id={name}
              ref={dateRef}
              suffixIcon={<SelectDownIcon color={disabled ? '#919195' : 'black'} />}
              popupClassName="date-menu-wrapper"
              className={`${textClass as string}`}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              disabledDate={disablePastDates}
              style={{
                border: '1px solid #E5E5E6',
                ...(icon !== '' && { paddingLeft: '50px' }),
              }}
              format="MM/DD/YYYY"
              {...props}
            />
          </Form.Item>
        </div>
      </div>
      {error && <p className="error-container">{error}</p>}
    </div>
  )
}

export default CustomDatePickerField
