import { createSlice } from '@reduxjs/toolkit'
import { type RootState } from 'store/Store'

const initialState = {
  name: '',
  faciliy_type: '',
  total_beds: '',
  image: '',
  street_address: '',
  house_no: '',
  city: '',
  zip_code: '',
  country: '',
  state: '',
  lat: '',
  lng: '',
  placeId: '',
}

export const welcomeDataSlice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {
    setWlcFormData: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setWlcFormData } = welcomeDataSlice.actions

export const welcomeData = (state: RootState) => state.welcome

export default welcomeDataSlice.reducer
