import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Image, message, Popover } from 'antd'
import { profile } from 'assets/imgs'
import { Logo, LogOut, MyProfileIcon, SidebarOpenClose, ThreeDotIcon } from 'assets/svgs'
import { LAYOUT_DATA } from 'constants/Constants'
import { siderMenu } from 'constants/SidebarMenu'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useWindowSize } from 'hooks/useWindowSize'
import { LOGOUT } from 'service/api/Common'
// import { socket } from 'socket/socket'
import { selectAuth, setLogOut } from 'store/slice/AuthSlice'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { ISideBar } from 'types'

import SwitchFacility from './SwitchFacility'

import './Layout.scss'

const Sidebar = ({ collapsed, setCollapsed }: ISideBar) => {
  const windowSize = useWindowSize()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isFacility = useAppSelector((state) => state?.auth?.auth?.profileDetails?.isFacility)
  const profileData = useAppSelector(selectAuth)

  const handleLogout = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await LOGOUT()
      if (response?.data?.statusCode === 1) {
        dispatch(setLogOut())
        navigate(`/login`)
        // socket.disconnect()
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }

    dispatch(setGlobalLoader(false))
  }

  const handleLogoutClick = () => {
    void handleLogout()
  }
  useEffect(() => {
    setCollapsed(windowSize.width <= 950)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize])

  const content = (
    <div>
      <div
        className="cursor-pointer p-4 flex gap-2 items-center"
        onClick={() => {
          if (isFacility) {
            navigate('/facility-profile')
          } else {
            navigate('/my-profile')
          }
        }}
      >
        <span>
          <MyProfileIcon className="flex" />
        </span>
        <p className="para-p1 text-neutral-800">{LAYOUT_DATA.MY_PROFILE}</p>
      </div>
      <div className="cursor-pointer p-4 flex gap-2 items-center border-t-stroke" onClick={handleLogoutClick}>
        <LogOut className="flex" />
        <p className="para-p1 text-error-500">{LAYOUT_DATA.LOGOUT}</p>
      </div>
    </div>
  )

  return (
    <div className="flex justify-between flex-col h-screen">
      <div>
        <div className={`flex ${!collapsed ? 'justify-between' : 'justify-center'} pb-10`}>
          {!collapsed && (
            <div className="flex items-center gap-1">
              <Logo />
            </div>
          )}

          {windowSize.width >= 950 ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                setCollapsed(!collapsed)
              }}
            >
              <SidebarOpenClose className="cursor-pointer" />
            </div>
          ) : (
            <Logo />
          )}
        </div>

        <SwitchFacility collapsed={collapsed} />
        <div className="scroll-container-sider">
          {/* <NursingHomeDropDown collapsed={collapsed} /> */}
          {siderMenu.map((subMenu) => {
            return (
              <div key={subMenu.id} className="wrapper-menu-div">
                <div
                  // key={subMenu.id}
                  className={`${subMenu.path === location.pathname ? 'submenu-div-selected' : ''} ${collapsed ? 'justify-center' : ''} flex gap-2 items-center submenu-div`}
                  onClick={() => {
                    navigate(subMenu.path)
                  }}
                >
                  <div className="flex">{subMenu.path === location.pathname ? subMenu.selectedIcon : subMenu.icon}</div>
                  <p style={{ color: subMenu.path === location.pathname ? '#220EB1' : '#68696C' }} className={`submenu-name para-p2 font-medium  ${collapsed ? 'none' : 'block'}`}>
                    {subMenu.name}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="sider-profile-box">
        {!collapsed ? (
          <div className="flex justify-between items-center ">
            <div className="flex gap-4 items-center">
              {isFacility ? (
                <Image
                  preview={false}
                  src={`${profileData?.auth?.profileDetails?.image ? `${profileData?.auth?.profileDetails?.base_url}${profileData?.auth?.profileDetails?.image}` : profile}`}
                  className="img-profile-picture"
                />
              ) : (
                <Image
                  preview={false}
                  src={`${profileData?.auth?.profileDetails?.image ? `${profileData?.auth?.profileDetails?.base_url}${profileData?.auth?.profileDetails?.image}` : profile}`}
                  className="img-profile-picture"
                />
              )}
              <div className={`flex flex-col gap-1 items-center`}>
                <p className="para-p3 font-normal text-neutral-800">
                  {isFacility
                    ? profileData?.auth?.profileDetails?.facility_name
                    : profileData?.auth?.profileDetails?.first_name + ' ' + profileData?.auth?.profileDetails?.last_name}
                </p>
                {/* <p className="para-p4 text-neutral-500">Administrator</p> */}
              </div>
            </div>

            <Popover content={content} placement="right">
              <ThreeDotIcon className="cursor-pointer icon" />
            </Popover>
          </div>
        ) : (
          <Popover content={content} placement="right">
            <Image
              preview={false}
              src={`${profileData?.auth?.profileDetails?.image ? `${profileData?.auth?.profileDetails?.base_url}${profileData?.auth?.profileDetails?.image}` : profile}`}
              className="img-profile-picture"
            />
          </Popover>
        )}
      </div>
    </div>
  )
}

export default Sidebar
