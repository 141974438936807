import { useEffect, useState } from 'react'
import { Dropdown, type MenuProps, message, Switch } from 'antd'
import { type SearchProps } from 'antd/es/input'
import type { ColumnsType } from 'antd/es/table'
import { EditPenIcon, EnvolepUploadIcon, PlusIcon, ThreeDotIcon, TimeLineIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import CustomModal from 'component/CustomModal/CustomModal'
import HeadingText from 'component/HeadingText'
import CustomTable from 'component/Table/CustomTable'
import TableImage from 'component/Table/TableImage'
import { BUTTON_STRING, COMMON, FACILITY_CONTACTS, PAGE_TITLE_STRING } from 'constants/Constants'
import { ADMIN_CONTACT_INVITATION_STATUS, ADMIN_CONTACT_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IProfileData } from 'types'
import { formatTitle } from 'utils/helper'

import AddMember from './Modal/AddMember'
import { EDIT_USER_CONTACT, GET_BILLING_CONTACTS, GET_CONTACT_PROFILE, GET_FACILITY_CONTACTS, SEND_INVITATION } from './api'
import type { IAddMember, IFacilityContactColumn } from './types'

const FacilityContacts = () => {
  const [hideActivityBtn, setHideActivityBtn] = useState<boolean>(false)
  const [billingListData, setBillingListData] = useState([])
  const [openActivityModal, setActivityModalOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [editId, setEditId] = useState<string>()
  const [openModal, setModalOpen] = useState<boolean>(false)
  const [contactDetails, setContactDetails] = useState<IProfileData | any>(null)
  const [contactListData, setContactListData] = useState([])
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(contactListData?.length)?.fill(false))
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(5)
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [sortBillingTable, setSortBillingTable] = useState({
    order: '',
    field: '',
  })
  const facilityId = useAppSelector((state) => state?.auth?.auth?.defaultFacility)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: PAGE_TITLE_STRING.FACILITY_CONTACTS,
      })
    )
  }, [dispatch])

  useEffect(() => {
    setCurrent(1)
    setTotal(0)
    setLimit(5)
  }, [facilityId])

  useEffect(() => {
    void handleGetBillingContact(sortBillingTable?.order, sortBillingTable?.field)
    void handleGetContacts(sortTable?.order, sortTable?.field, current, limit)
  }, [!openModal, sortTable, sortBillingTable, search, facilityId, current, limit])

  const handleGetBillingContact = async (order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const res = await GET_BILLING_CONTACTS(facilityId, search, order, field)
      if (res?.status === 200) {
        setBillingListData(res?.data?.data)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleGetContacts = async (order: string, field: string, page: number, pageSize: number) => {
    dispatch(setGlobalLoader(true))
    try {
      const res = await GET_FACILITY_CONTACTS(facilityId, search, order, field, page, pageSize)
      if (res?.status === 200) {
        setContactListData(res?.data?.data)
        setTotal(res?.data?.total)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditUserData = async (id: string, values: IAddMember) => {
    dispatch(setGlobalLoader(true))
    const response = await EDIT_USER_CONTACT(id, values)
    try {
      if (response?.data.statusCode === 1) {
        void handleGetBillingContact(sortBillingTable?.order, sortBillingTable?.field)
        void handleGetContacts(sortTable?.order, sortTable.field, current, limit)
        void message.success(response?.data?.message)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleGetContactProfile = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await GET_CONTACT_PROFILE(id)
    try {
      if (response?.data?.statusCode === 1) {
        setContactDetails(response?.data?.data)
        setModalOpen(true)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setCurrent(1)
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
    }
  }

  const showAddEditModal = () => {
    setContactDetails(null)
    setEditId('')
    setModalOpen(true)
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleSendInvitation = async (data: { email: string }) => {
    dispatch(setGlobalLoader(true))
    const response = await SEND_INVITATION(data)
    try {
      if (response?.data.statusCode === 1) {
        void message.success(response?.data?.message)
      }
    } catch (error: any) {
      console.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleChangePage = (page: number, pageSize?: number) => {
    setCurrent(page)
    if (pageSize) setLimit(pageSize)
  }

  const handleEdit = (id: string) => {
    setEditId(id)
    void handleGetContactProfile(id)
  }

  const onSwitchChange = (checked: boolean, data: any, id: string) => {
    const newStatus = checked ? 'active' : 'in_active'
    const newData = { ...data, status: newStatus }
    void handleEditUserData(id, newData)
  }

  const handleRowClick = (editId: string) => {
    // void handleGetRowContactProfile(editId)
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: 1,
          label: (
            <div
              className="option-wrapper w-full p-4"
              key="1"
              onClick={() => {
                handleEdit(record.id)
                if (record.invitation_status === 'invited') {
                  setHideActivityBtn(true)
                }
              }}
            >
              <p className="flex items-center gap-2">
                <EditPenIcon /> <span className="para-p1 font-normal text-neutral-800">{BUTTON_STRING.EDIT}</span>
              </p>
            </div>
          ),
        },
        {
          label: (
            <div
              className="option-wrapper w-full p-4"
              key="2"
              onClick={() => {
                if (record?.invitation_status !== 'invited') {
                  setActivityModalOpen(true)
                  setHideActivityBtn(false)
                } else {
                  void handleSendInvitation({ email: record?.email })
                }
              }}
            >
              <p className="flex items-center gap-2">
                {record.invitation_status === 'invited' ? <EnvolepUploadIcon /> : <TimeLineIcon />}
                <span className="para-p1 font-normal text-neutral-800">{record.invitation_status === 'invited' ? BUTTON_STRING.SEND_INVITATION : BUTTON_STRING.VIEW_ACTIVITY}</span>
              </p>
            </div>
          ),
          key: 2,
        },
        {
          label: (
            <div className="option-wrapper w-full p-4" key="3">
              <p className="flex items-center justify-between w-full gap-2">
                <span className="para-p1 font-normal text-neutral-800">{FACILITY_CONTACTS.ACTIVE}</span>
                <Switch
                  checked={record.status === 'active'}
                  onChange={(checked) => {
                    onSwitchChange(checked, record, record?.id)
                  }}
                />
              </p>
            </div>
          ),
          key: 3,
        },
      ],
    }
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    if (sorter.field === 'facility_id') {
      return
    }
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }
  const handleBillingTableChange = (_pagination: any, _filters: any, sorter: any) => {
    if (sorter.field === 'facility_id') {
      return
    }
    setSortBillingTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const columns: ColumnsType<IFacilityContactColumn> = [
    {
      title: FACILITY_CONTACTS.TABLE.TEAM_MEMBER,
      dataIndex: 'first_name',
      key: 'first_name',
      align: 'start',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 300,
      render: (_value, record) => {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const name = record.first_name + ' ' + record.last_name
        return (
          <div className="w-full min-w-300">
            <TableImage imageClass="rounded-50" name={name} baseUrl={record?.base_url as string} image={record?.image as string} />
          </div>
        )
      },
    },
    {
      title: FACILITY_CONTACTS.TABLE.EMAIL,
      dataIndex: 'email',
      key: 'email',
      align: 'start',
      width: 300,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return (
          <div className="w-full min-w-300">
            <HeadingText text={record?.email} />
          </div>
        )
      },
    },
    {
      title: FACILITY_CONTACTS.TABLE.STATUS,
      dataIndex: 'status',
      key: 'status_contact',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 200,
      className: 'center-align',
      render: (_value, record) => {
        return (
          <div className="w-full min-w-200">
            <HeadingText
              classString={`${record?.status === ADMIN_CONTACT_STATUS.INVITED ? 'invited-tag' : record?.status === ADMIN_CONTACT_STATUS.ACTIVE ? 'active-tag' : 'inactive'} `}
              text={record?.status === 'active' ? 'Active' : 'Inactive'}
            />
          </div>
        )
      },
    },
    {
      title: FACILITY_CONTACTS.TABLE.INVITATION_STATUS,
      dataIndex: 'invitation_status',
      key: 'invitation_status',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      className: 'center-align',
      width: 200,
      render: (_value, record) => {
        return (
          <div className="w-full min-w-200">
            <HeadingText
              classString={`${record?.invitation_status === ADMIN_CONTACT_INVITATION_STATUS.INVITED ? 'invited-tag' : 'active-tag'} `}
              text={formatTitle(record?.invitation_status)}
            />
          </div>
        )
      },
    },
    {
      title: FACILITY_CONTACTS.TABLE.FACILITY_ACCESS,
      dataIndex: 'facility_id',
      key: 'facility_id',
      align: 'center',
      width: 200,
      sorter: (a, b) => {
        const aLength = Array.isArray(a.facility_id) ? a.facility_id.length : 0
        const bLength = Array.isArray(b.facility_id) ? b.facility_id.length : 0
        return aLength - bLength
      },
      sortDirections: ['ascend', 'descend'],
      className: 'center-align',
      render: (_value, record) => {
        const facilityIdLength = Array.isArray(record.facility_id) ? record.facility_id.length : 0
        return (
          <div className="w-full min-w-200">
            <HeadingText classString="text-neutral-800 para-p3" text={facilityIdLength.toString()} />
          </div>
        )
      },
    },
    {
      title: FACILITY_CONTACTS.TABLE.LAST_MODIFIED,
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 200,
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      className: 'center-align',
      render: (_value, record) => {
        const formattedDate = dayjs(record?.updated_at).format('MM/DD/YYYY, hh:mm A')
        return (
          <div className="w-full min-w-200">
            <HeadingText classString="text-neutral-800 para-p3 font-normal" text={formattedDate} />
          </div>
        )
      },
    },
    {
      title: FACILITY_CONTACTS.TABLE.ACTIONS,
      dataIndex: '',
      // hidden: !permits.edit,
      key: 'action_contact',
      align: 'center',
      width: 200,
      render: (_value, record) => {
        return (
          <div className="flex gap-2 justify-center">
            <Dropdown
              rootClassName="table-action-dropdown action-dropdown"
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
              trigger={['click']}
            >
              <CustomButton isIcon svgIcon={<ThreeDotIcon className="cursor-pointer user-icon" />} text="" className="p-1-imp w-fit h-fit three-dot-icon" type={'default'} />
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const tableButton = <CustomButton isIcon svgIcon={<PlusIcon />} text={BUTTON_STRING.ADD_USER} onClick={showAddEditModal} type="primary" className="rounded-3 border-0" />
  return (
    <div className="flex flex-col gap-6 relative">
      <div className="flex flex-col items-start gap-2 w-full pt-123">
        <HeadingText text={FACILITY_CONTACTS.BILLING_CONTACTS} classString="mobile-heading-h4 font-medium text-neutral-800" />
        <CustomTable
          column={columns}
          data={billingListData}
          total={undefined}
          pagination={false}
          onChange={handleBillingTableChange}
          isSearch
          tableButton={tableButton}
          isWithoutTable
          onSearchChange={(e) => {
            handleChange(e.target.value)
          }}
          onSearch={onSearch}
          searchPlaceholder={COMMON.SEARCH}
        />
      </div>
      <div className="flex flex-col items-start gap-2 w-full">
        <HeadingText text={FACILITY_CONTACTS.CONTACTS} classString="mobile-heading-h4 font-medium text-neutral-800" />
        <CustomTable
          name={FACILITY_CONTACTS.MEMBERS}
          column={columns}
          data={contactListData}
          total={total}
          limit={limit}
          current={current}
          search={search}
          onChange={handleTableChange}
          onChangePage={handleChangePage}
          setModalOpen={handleRowClick}
        />
      </div>
      {openModal && (
        <CustomModal
          width={915}
          open={openModal}
          title={editId ? FACILITY_CONTACTS.EDIT_CONTACT : FACILITY_CONTACTS.ADD_CONTACT}
          footer={null}
          className="hide-closeicon-modal"
          onCancel={() => {
            setContactDetails(null)
            setModalOpen(false)
          }}
          content={
            <AddMember
              openModal={openModal}
              setOpenModal={setModalOpen}
              editId={editId}
              contactDetails={contactDetails}
              setContactDetails={setContactDetails}
              setSortTable={setSortTable}
            />
          }
        />
      )}
    </div>
  )
}

export default FacilityContacts
