import { message } from 'antd'
import { MAX_FILE_SIZE_MB } from 'constants/Constants'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import { pickBy } from 'lodash'

dayjs.extend(duration)
dayjs.extend(customParseFormat)

export const userToken = () => {
  const authPersist = localStorage.getItem('persist:root')
  if (!authPersist) return
  const jsonAuth = JSON?.parse(authPersist)
  const { accessToken } = JSON.parse(jsonAuth.auth)
  return accessToken
}

const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) return 'th' // handles 11th, 12th, 13th
  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const formatDateStrings = (dateString: any) => {
  const date = dayjs(dateString)
  const day = date?.date()
  const month = date?.format('MMM')
  const time = date?.format('h:mm A')
  return `${day}${getOrdinalSuffix(day)} ${month}, ${time}`
}

export const formatSecondsToHHMM = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  return `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m`
}

export const convertSecondsToHours = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  // Pad hours and minutes to ensure two digits
  const paddedHours = String(hours).padStart(2, '0')
  const paddedMinutes = String(minutes).padStart(2, '0')

  return `${paddedHours}:${paddedMinutes} hrs`
}

export const convertMMSSToSeconds = (timeString: string): number => {
  const [minutes, seconds] = timeString.split(':').map(Number)
  const duration = dayjs.duration({ minutes, seconds })
  return duration.asSeconds()
}

export const validateFileSize = (file: File, maxSizeMB: number): string | null | boolean => {
  const fileSizeInMB = file.size / (1024 * 1024) // Convert file size to MB
  if (fileSizeInMB > maxSizeMB) {
    void message.error(`File size should be less than ${MAX_FILE_SIZE_MB}MB`)
    return false
  }
  return true
}

const customPredicate = (value: any) => value !== null && value !== undefined && value !== ''

export const formatQuerry = (querry: any) => {
  const query: any = pickBy(querry, customPredicate)

  const queryParams = new URLSearchParams(query)

  // Get the query string
  const queryString = queryParams.toString()

  return queryString
}
