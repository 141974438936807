import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form } from 'antd'
import { LogoIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import InputField from 'component/InputField/InputField'
import { COMMON, SIGNUP } from 'constants/Constants'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { selectSignUp, setFormData } from 'store/slice/SignupDataSlice'
import type { IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { emailValidations } from 'utils/Validations'

import { type ISignUp } from './types'

import './SignUp.scss'

const SignUp = () => {
  const [signUpData, setSignUpData] = useState({
    email: '',
  })
  const [error, setError] = useState({
    email: '',
  })
  const dispatch = useAppDispatch()
  const formData = useAppSelector(selectSignUp)
  const navigate = useNavigate()
  const primaryColor = localStorage.getItem('theme-color') ?? '#220EB1'

  const onFinish = (values: ISignUp) => {
    const payload = {
      email: values?.email?.toLowerCase(),
    }
    dispatch(setFormData(payload))
    navigate('/set-password')
  }

  const handleLogin = () => {
    navigate('/facility-login')
  }

  return (
    <div className="bg-neutral-50 screen-height">
      <div className="w-full flex flex-col gap-12 items-center pt-140">
        <div className="mx-auto max-w-400 w-full flex flex-col gap-10 justify-center items-center">
          <div className="flex gap-6 flex-col items-center">
            <LogoIcon />
            <p className="desktop-heading-h3 font-medium">{SIGNUP.LETS_START}</p>
          </div>

          <div className="w-full min-w-full">
            <Form
              initialValues={formData}
              layout="vertical"
              onFinish={onFinish}
              className=" min-w-full"
              requiredMark={false}
              onFieldsChange={(allFields: IFieldData[]) => {
                handleFieldsChange(allFields, setError)
              }}
            >
              <InputField name="email" rules={emailValidations} placeholder={COMMON.EMAIL} error={error?.email} value={formData.email || signUpData?.email} />
              <CustomButton text={COMMON.CONTINUE} htmlType="submit" />

              <div className="divider">
                <span className="text">{COMMON.OR}</span>
              </div>

              <div className="signup-string">
                <p>{SIGNUP.ALREADY}</p>
                <span onClick={handleLogin} style={{ color: primaryColor }}>
                  {COMMON.LOGIN}
                </span>
              </div>
            </Form>
          </div>
        </div>
        <div className="flex gap-1 text-neutral-500 para-p3">
          <span>{SIGNUP.SIGN_UP_AGREE}</span>
          <span style={{ color: primaryColor }} className="cursor-pointer">
            {SIGNUP.TERM}
          </span>
          <span>{SIGNUP.AND}</span>
          <span style={{ color: primaryColor }} className="cursor-pointer">
            {SIGNUP.PRIVACY_POLICY}
          </span>
        </div>
      </div>
    </div>
  )
}

export default SignUp
