import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Autocomplete, type Libraries, useLoadScript } from '@react-google-maps/api'
import { Button, Form, message } from 'antd'
import { DownIcon } from 'assets/svgs'
import InputField from 'component/InputField/InputField'
import { COMMON, WELCOME } from 'constants/Constants'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { setWlcFormData, welcomeData } from 'store/slice/WelcomeDataSlice'
import type { IAddressState, IFieldData } from 'types'
import { handleFieldsChange, mapAddressToState } from 'utils/helper'
import { commonInputFieldValidation } from 'utils/Validations'

import { updateFacilityProfile } from '../api'
import { type IMultiLevelForm, type IUpdateProfileData } from '../types'

const libraries: Libraries = ['places']

const FacilityAddress = ({ onClick, back }: IMultiLevelForm) => {
  const [error, setError] = useState({
    street_address: '',
    city: '',
    zip_code: '',
    house_no: '',
  })
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string, // Replace with your Google API key
    libraries, // Pass the libraries array correctly
  })

  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id') ?? ''
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const formData = useSelector(welcomeData)

  const validateFields = async (fields: string[]) => {
    try {
      await form.validateFields(fields)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({ ...formData })
      void validateFields(['city', 'zip_code', 'street_address'])
    }
  }, [formData])

  if (loadError) {
    return <div>Error loading Google Maps</div>
  }
  if (!isLoaded) {
    return <div>Loading...</div>
  }

  const onFinish = (value: any) => {
    const payload = {
      house_no: value?.house_no,
      street_address: value?.street_address,
      zip_code: value?.zip_code,
      country: value?.country,
      state: value?.state,
      city: value?.city,
      latitude: formData?.lat,
      longitude: formData?.lng,
      place_id: formData?.placeId,
      name: formData?.name,
      image: formData?.image,
      facility_type: formData?.faciliy_type,
      total_beds: Number(formData.total_beds),
    }
    dispatch(setWlcFormData(payload))
    if (onClick) void handleUpdateProfile(payload)
  }

  const handleUpdateProfile = async (data: IUpdateProfileData) => {
    dispatch(setGlobalLoader(true))
    const response = await updateFacilityProfile(id, data)
    if (response?.data?.statusCode === 1) {
      navigate('/login')
      void message.success(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onLoad = (autocompleteInstance: React.SetStateAction<google.maps.places.Autocomplete | null>) => {
    setAutocomplete(autocompleteInstance)
  }

  // Handle place selection changes
  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete?.getPlace()
      if (place?.geometry && place?.geometry?.location) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        dispatch(setWlcFormData({ lat, lng }))
      } else {
        console.log('No geometry available for this place.')
      }

      if (place?.address_components) {
        const addressData: IAddressState = mapAddressToState(place?.address_components)
        if (!addressData?.street_address && place?.formatted_address) {
          addressData.street_address = place?.formatted_address ?? ''
        }
        dispatch(setWlcFormData({ ...addressData, placeId: place?.place_id }))
      } else {
        console.log('No address available for this place.')
      }
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="bg-white mx-auto max-w-532  flex flex-col gap-12 items-center welcome-card w-full ">
      <div className="back-btn-wrapper flex justify-center items-center p-2 cursor-pointer" onClick={back}>
        <DownIcon className="back-icon" />
        <p className="text-primary-500 para-p2">{COMMON.BACK}</p>
      </div>
      <div className="flex flex-col items-center gap-10 padding-32 w-full min-w-full wlc-box-shadow ">
        <div className="flex flex-col items-center gap-2">
          <p className="desktop-heading-h3 font-medium text-center">{WELCOME.ADD_FACILITY_ADDRESS}</p>
        </div>
        <div className="flex flex-col gap-6 items-start w-full">
          <Form
            form={form}
            layout="vertical"
            onFinish={(e) => {
              onFinish(e)
            }}
            className="min-w-full"
            requiredMark={false}
            onFieldsChange={(allFields: IFieldData[]) => {
              handleFieldsChange(allFields, setError)
            }}
            onValuesChange={(changedValues, values) => {
              dispatch(setWlcFormData(values))
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
              }
            }}
            initialValues={{ ...formData }}
          >
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
              options={{
                componentRestrictions: { country: 'us' },
              }}
            >
              <InputField
                delay={100}
                inputPlaceholder=""
                name="street_address"
                placeholder={COMMON.STREET}
                rules={commonInputFieldValidation('street address')}
                error={error.street_address}
              />
            </Autocomplete>

            <InputField value={getValue('house_no')} name="house_no" placeholder={COMMON.APARTMENT} />

            <InputField
              value={getValue('zip_code')}
              name="zip_code"
              placeholder={COMMON.ZIP}
              error={error.zip_code}
              maxLength={5}
              delay={100}
              rules={commonInputFieldValidation('zip code')}
            />
            <InputField value={getValue('city')} name="city" placeholder={COMMON.CITY} error={error.city} delay={100} rules={commonInputFieldValidation('city')} />

            <InputField disabled name="state" placeholder={COMMON.STATE} />
            <InputField disabled name="country" placeholder={COMMON.COUNTRY} />

            <Button type="primary" htmlType="submit" className="common-button w-full">
              {COMMON.CONTINUE}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default FacilityAddress
