import React, { useState } from 'react'
import { Image } from 'antd'
import type { ImageUploadProps } from 'types'

import './UploadMedia.scss'

const UploadMedia: React.FC<ImageUploadProps> = ({
  imageUrl,
  handleChange,
  baseUrl,
  className,
  imgWidth,
  imgHeight,
  icon,
  edit,
  applyOverlay = false,
  styleImgClass,
  imgClass,
}) => {
  const [localImageUrl, setLocalImageUrl] = useState<string | null>(null)

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const localUrl = URL.createObjectURL(file)
      setLocalImageUrl(localUrl)
      if (handleChange) {
        handleChange(e) // Call the original handleChange function if provided
      }
    }
  }

  const imageContainerClass = `${className as string} ${applyOverlay ? 'relative' : ''}`
  const overlayClass = `absolute upload-bg-overlay flex items-center justify-center ${styleImgClass as string}`

  return (
    <div className={imageContainerClass}>
      <label htmlFor="upload-button">
        {(localImageUrl ?? imageUrl) ? (
          <div>
            <Image
              width={imgWidth}
              height={imgHeight}
              // eslint-disable-next-line
              src={baseUrl ? `${baseUrl}${imageUrl as string}` : (localImageUrl as string)}
              alt="Uploaded Image Preview"
              className={`upload-profile-img ${imgClass as string}`}
              preview={false}
            />
            {applyOverlay && <div className={overlayClass}>{icon}</div>}
          </div>
        ) : (
          <div className={styleImgClass}>{icon}</div>
        )}
      </label>
      {edit && <input type="file" id="upload-button" className="media-button" onChange={onFileChange} accept="image/png, image/jpeg" />}
    </div>
  )
}

export default UploadMedia
