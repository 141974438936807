import dayjs from 'dayjs'
import type { IAddressComponent, IFieldData } from 'types'

export const handleFieldsChange = (allFields: IFieldData[], setError: React.Dispatch<React.SetStateAction<any>>) => {
  allFields.forEach((field: IFieldData) => {
    const fieldName = field.name[0]
    const fieldErrors = field.errors ?? []
    setError((prev: any) => ({
      ...prev,
      [fieldName]: fieldErrors.length > 0 ? fieldErrors[0] : '',
    }))
  })
}

export const paginationDetails = (limit: number | string | undefined, currentPage: number | string | undefined = 1, total: number | string | undefined = 0) => {
  const numLimit = Number(limit) || 10
  const numCurrentPage = Math.max(1, Number(currentPage))
  const numTotal = Number(total) || 0

  let start = (numCurrentPage - 1) * numLimit + 1
  if (start > numTotal) {
    start = numTotal > 0 ? numTotal : 0
  }

  let end = numCurrentPage * numLimit
  if (end > numTotal) {
    end = numTotal
  }

  if (numTotal === 0) {
    start = 0
    end = 0
  }

  if (start < 1 && numTotal > 0) {
    start = 1
  }

  return `Showing ${start} - ${end} of ${numTotal}`
}

export const formatTime = (time?: string): string => {
  if (!time) return '-'

  // If the time string doesn't include a date, append a default date
  const dateTime = `1970-01-01T${time}`
  return dayjs(dateTime).format('h:mm A')
}

export const formatTitle = (name?: string): string => (name ? name.replace(/_/g, ' ')?.replace(/\b\w/g, (char) => char?.toUpperCase()) : '')

export const transformOptionGroup = (response: any): any[] => {
  const transformedOption: any[] = []
  for (const [key, value] of Object.entries(response.data)) {
    const options: any[] = (value as any[]).map((item) => ({
      label: item?.name,
      value: item?.id,
    }))
    transformedOption.push({
      label: formatTitle(key),
      title: key,
      options,
    })
  }
  return transformedOption
}

// helper function for set dynamic string and id for breadcrumb label
export const resolveBreadcrumbLabel = (url: string, location: any, breadcrumbMap: Record<string, string>): { url: string; label: string } => {
  let label = breadcrumbMap[url]
  if (url === '/providers/provider-profile') {
    if (location?.state?.record) localStorage.setItem('name', location?.state?.record?.provider?.first_name ?? 'Catherine James')
    const name = location?.state?.record?.provider?.first_name ?? localStorage.getItem('name') ?? 'Catherine James'
    label = `${formatTitle(name)}`
  }

  return { url, label }
}

// remove sorting when create data for modal
export const removeSorting = (setSortTable: React.Dispatch<React.SetStateAction<{ order: string; field: string }>>) => {
  const sortIcons = document.querySelectorAll('.anticon-caret-down, .anticon-caret-up')
  const sortBg = document.querySelectorAll('.ant-table-cell, .ant-table-column-has-sorters')
  sortBg.forEach((bg) => {
    if (bg.classList.contains('ant-table-column-sort')) {
      bg.classList.remove('ant-table-column-sort')
    }
  })
  sortIcons.forEach((icon) => {
    if (icon.classList.contains('active')) {
      icon.classList.remove('active')
    }
  })
  setSortTable({
    order: '',
    field: '',
  })
}

//  check canvas isblank or not for signature pad
export const isCanvasBlank = (canvas: HTMLCanvasElement): boolean => {
  const context = canvas.getContext('2d')
  if (!context) return true

  // Get pixel data of the entire canvas
  const pixelData = context.getImageData(0, 0, canvas.width, canvas.height).data

  // Check if any pixel is non-transparent
  for (let i = 0; i < pixelData.length; i += 4) {
    if (pixelData[i + 3] !== 0) {
      // If alpha (fourth channel) is not 0, canvas is not blank
      return false
    }
  }

  return true // All pixels are transparent, canvas is blank
}

export const mapAddressToState = (addressArray: IAddressComponent[]) => {
  const addressObj = {
    street_address: '',
    house_no: '',
    zip_code: '',
    country: '',
    state: '',
    city: '',
  }

  addressArray?.forEach((component: IAddressComponent) => {
    const types = component.types

    if (types.includes('street_number')) {
      addressObj.street_address = String(component.long_name) + ' ' + String(addressObj.street_address)
    }

    if (types.includes('route')) {
      addressObj.street_address = String(addressObj.street_address) + ' ' + String(component.long_name)
    }

    if (types.includes('locality')) {
      addressObj.city = component.long_name
    }

    if (types.includes('administrative_area_level_1')) {
      addressObj.state = component.long_name
    }

    if (types.includes('country')) {
      addressObj.country = component.long_name
    }

    if (types.includes('postal_code')) {
      addressObj.zip_code = component.long_name
    }
  })

  console.log('first', addressObj)

  return addressObj
}
