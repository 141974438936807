import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, message } from 'antd'
import { DownIcon, HospitalSolid } from 'assets/svgs'
import UploadMedia from 'component/UploadMedia/UploadMedia'
import { COMMON, MAX_FILE_SIZE_MB, WELCOME } from 'constants/Constants'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { setWlcFormData, welcomeData } from 'store/slice/WelcomeDataSlice'
import { validateFileSize } from 'utils/utils'

import { mediaUpload } from '../api'
import { type IMultiLevelForm } from '../types'

const ProfilePic = ({ onClick, back, baseUrl }: IMultiLevelForm) => {
  const dispatch = useDispatch()
  const formData = useSelector(welcomeData)
  const [imageResponse, setImageResponse] = useState<string>('')

  useEffect(() => {
    if (formData?.image) {
      setImageResponse(formData?.image)
    }
  }, [formData])

  const onFinish = () => {
    const values = {
      image: imageResponse,
    }
    dispatch(setWlcFormData(values))
    if (onClick) onClick()
  }

  const handleMediaUpload = async (data: string | Blob) => {
    dispatch(setGlobalLoader(true))
    try {
      const formData = new FormData()
      formData.append('image', data)
      formData.append('folder', 'facility')
      const response = await mediaUpload(formData)
      if (response?.data?.statusCode === 1) {
        void message.success(response?.data?.message)
        setImageResponse(response?.data?.data?.image)
        dispatch(
          setWlcFormData({
            ...formData,
            image: response?.data?.data?.image,
          })
        )
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error) {
      void message.error('Failed to upload image. Please try again.')
    }
    dispatch(setGlobalLoader(false))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]

      const validSize = validateFileSize(file, MAX_FILE_SIZE_MB)
      if (!validSize) {
        return
      }
      void handleMediaUpload(file)
    }
  }

  return (
    <div className="bg-white mx-auto max-w-532 flex flex-col gap-12 items-center welcome-card w-full">
      <div className="back-btn-wrapper flex justify-center items-center p-2 cursor-pointer" onClick={back}>
        <DownIcon className="back-icon" />
        <p className="text-primary-500 para-p2">{COMMON.BACK}</p>
      </div>
      <div className="flex flex-col items-center gap-10 padding-32 w-full min-w-full wlc-box-shadow ">
        <div className="flex flex-col items-center gap-2">
          <p className="desktop-heading-h3 font-medium text-center">{WELCOME.SET_PROFILE_PIC}</p>
        </div>
        <div className="flex flex-col gap-10 items-start w-full">
          <UploadMedia
            baseUrl={baseUrl as string}
            className="flex justify-center items-center w-full"
            icon={<HospitalSolid />}
            imgHeight={150}
            imgWidth={150}
            handleChange={handleChange}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            imageUrl={imageResponse}
            edit={true}
            styleImgClass="upload-signup-img"
            imgClass="upload-img-w-150"
          />
          <Button type="primary" onClick={onFinish} className="common-button w-full">
            {COMMON.CONTINUE}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ProfilePic
