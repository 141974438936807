import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import { DownIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import InputField from 'component/InputField/InputField'
import CustomSelect from 'component/Select/CustomSelect'
import { COMMON, WELCOME } from 'constants/Constants'
import { setWlcFormData, welcomeData } from 'store/slice/WelcomeDataSlice'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { commonInputFieldValidation, commonSelectFieldValidation } from 'utils/Validations'

import { type IMultiLevelForm, type ISignUpWelcomeFormData } from '../types'

const CompleteProfile = ({ onClick, back, facilityData }: IMultiLevelForm) => {
  const [error, setError] = useState({
    name: '',
    faciliy_type: '',
    total_beds: '',
  })
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const formData = useSelector(welcomeData)

  useEffect(() => {
    form.setFieldsValue({
      name: facilityData?.name,
      faciliy_type: formData?.faciliy_type || '',
      total_beds: formData?.total_beds || '',
    })
  }, [facilityData, form])

  const onFinish = (values: ISignUpWelcomeFormData) => {
    const payload = {
      name: facilityData?.name,
      faciliy_type: values?.faciliy_type,
      total_beds: values?.total_beds,
    }
    dispatch(setWlcFormData(payload))
    if (onClick) onClick()
  }

  const staticFacilityOption = [
    {
      value: 'Custom',
      label: 'Custom',
    },
    {
      value: 'Nursing Home',
      label: 'Nursing Home',
    },
    {
      value: 'Behavioral Health',
      label: 'Behavioral Health',
    },
    {
      value: 'Clinic',
      label: 'Clinic',
    },
    {
      value: 'Home Health',
      label: 'Home Health',
    },
    {
      value: 'Hospital',
      label: 'Hospital',
    },
  ]

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="bg-white mx-auto max-w-532 w-full flex flex-col gap-12  items-center welcome-card ">
      <div className="back-btn-wrapper flex justify-center items-center p-2 cursor-pointer" onClick={back}>
        <DownIcon className="back-icon" />
        <p className="text-primary-500 para-p2">{COMMON.BACK}</p>
      </div>
      <div className="flex flex-col items-center gap-10 padding-32 w-full min-w-full wlc-box-shadow ">
        <div className="flex flex-col items-center gap-2">
          <p className="desktop-heading-h3 font-medium text-center">{WELCOME.COMP_YOUR_PROFILE}</p>
          <p className="max-w-371 w-full para-p2 font-normal text-neutral-500 text-center">{WELCOME.COMP_YOUR_PROFILE_SUB}</p>
        </div>
        <div className="flex flex-col gap-6 items-start w-full">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="h-full min-w-full flex flex-col"
            requiredMark={false}
            onFieldsChange={(allFields: IFieldData[]) => {
              handleFieldsChange(allFields, setError)
            }}
          >
            <InputField
              name="name"
              placeholder={COMMON.NAME_OF_FACILITY}
              error={error.name}
              rules={commonInputFieldValidation('facility Name')}
              value={getValue('name') || formData?.name || ''}
              delay={100}
            />
            <CustomSelect
              label={COMMON.FACILITY_TYPE}
              options={staticFacilityOption}
              name="faciliy_type"
              rules={commonSelectFieldValidation('facility type')}
              error={error.faciliy_type}
              value={getValue('faciliy_type') || formData?.faciliy_type || ''}
            />
            <InputField
              name="total_beds"
              placeholder={COMMON.NO_OF_BED}
              rules={commonInputFieldValidation('total no of beds')}
              error={error.total_beds}
              value={getValue('total_beds') || formData?.total_beds || ''}
            />
            <CustomButton text={COMMON.CONTINUE} htmlType="submit" />
          </Form>
        </div>
      </div>
    </div>
  )
}

export default CompleteProfile
