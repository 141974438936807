import { useEffect, useState } from 'react'
import { Form, message, Switch } from 'antd'
import { CloseIcon, FacilityIcon, UsaIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import HeadingText from 'component/HeadingText'
import InputField from 'component/InputField/InputField'
import CustomMultiSelect from 'component/Select/CustomMultiSelect'
import { BUTTON_STRING, COMMON, FACILITY_CONTACTS } from 'constants/Constants'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData } from 'types'
import { formatTitle, handleFieldsChange, removeSorting } from 'utils/helper'
import { commonInputFieldValidation, commonSelectFieldValidation, emailValidations, mobileValidations } from 'utils/Validations'

import { ADD_USER, EDIT_USER_CONTACT, GET_FACILITY_ACCESS, GET_FACILITY_PERMISSION } from '../api'
import { type IAddMember, type IAddMemberModal, type IPermissionOption } from '../types'

import '../FacilityContacts.scss'

const AddMember = ({ openModal, setOpenModal, editId, contactDetails, setContactDetails, setSortTable }: IAddMemberModal) => {
  const [statusCheck, setStatusCheck] = useState(true)
  const [permissionTag, setPermissionTag] = useState<any[]>([])
  const [facilityTag, setFacilityTag] = useState<any[]>([])
  const [permissionOption, setPermissionOption] = useState<IPermissionOption[]>([])
  const [failityAccessOption, setFacilityAccessOption] = useState<IPermissionOption[]>([])
  const [error, setError] = useState({
    first_name: '',
    mobile_no: '',
    last_name: '',
    email: '',
    permissions: '',
    facility_id: '',
  })
  const [contactData, setContactData] = useState({
    first_name: '',
    mobile_no: '',
    last_name: '',
    email: '',
  })

  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  useEffect(() => {
    if (openModal) {
      void getFacilitiesPermissionOption()
      void getFacilitiesAccessOption()
    }

    form.setFieldsValue(contactDetails)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  useEffect(() => {
    if (contactDetails) {
      const checkMark = contactDetails?.status === 'active'
      setStatusCheck(checkMark)
      const permissionIds: any = contactDetails?.permission?.map((permission) => permission?.permission_id)
      setPermissionTag(permissionIds)
      const facilityIds = contactDetails?.facility?.map((item: { id: string }) => {
        const option = failityAccessOption.find((option) => option?.id === item?.id)
        return option ? option.id : null
      })
      setFacilityTag(facilityIds)
    }
  }, [contactDetails, failityAccessOption])

  useEffect(() => {
    setError((prevError) => ({
      ...prevError,
      facility_id: facilityTag?.length > 0 ? '' : prevError.facility_id,
      permissions: permissionTag?.length > 0 ? '' : prevError.permissions,
    }))
  }, [permissionTag, facilityTag])

  const usa = FACILITY_CONTACTS.COUNTRY_CODE

  const onFinish = (values: IAddMember) => {
    const dialCode: any = usa
    const statusValue = statusCheck ? 'active' : 'in_active'
    const updatedValues = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email?.toLowerCase(),
      mobile_no: values?.mobile_no,
      country_code: dialCode,
      permissions: permissionTag,
      facility_id: facilityTag,
      status: statusValue,
    }
    void handleAddUser(updatedValues)
  }

  const handleCancel = () => {
    setContactDetails()
    setOpenModal(false)
  }

  const onSwitchChange = (checked: boolean) => {
    if (checked) {
      setStatusCheck(checked)
    } else {
      setStatusCheck(checked)
    }
  }

  const handlePermissionChange = (selectedValue: any[]) => {
    setPermissionTag(selectedValue)
  }

  const handleAddUser = async (values: IAddMember) => {
    dispatch(setGlobalLoader(true))
    let response

    if (editId) {
      response = await EDIT_USER_CONTACT(editId, values)
    } else {
      response = await ADD_USER(values)
    }

    if (response?.data.statusCode === 1) {
      setSortTable && removeSorting(setSortTable)
      void message.success(response?.data?.message)
      setOpenModal(false)
      form.resetFields()
    }
    dispatch(setGlobalLoader(false))
  }

  const getFacilitiesPermissionOption = async () => {
    dispatch(setGlobalLoader(true))
    const response = await GET_FACILITY_PERMISSION()

    if (response?.data.statusCode === 1) {
      const permission = response?.data?.data
      const modifiedPermissions = permission.map((item: IPermissionOption) => ({
        ...item,
        value: item.id,
        name: formatTitle(item?.name),
      }))
      setPermissionOption(modifiedPermissions)
    }
    dispatch(setGlobalLoader(false))
  }

  const getFacilitiesAccessOption = async () => {
    dispatch(setGlobalLoader(true))
    const response = await GET_FACILITY_ACCESS()

    if (response?.data?.statusCode === 1) {
      const facilityOpt = response?.data?.data
      const modifiedFacility = facilityOpt?.map((item: IPermissionOption) => ({
        ...item,
        value: item?.id,
        name: item?.name?.replace(/_/g, ' ').replace(/^\w/, (c) => c?.toUpperCase()),
      }))
      setFacilityAccessOption(modifiedFacility)
    } else {
      void message.error(response?.data?.message)
    }

    dispatch(setGlobalLoader(false))
  }

  const handleDeleteTag = (id: number) => {
    const updatedPermissionTag = [...permissionTag]
    updatedPermissionTag.splice(id, 1)
    setPermissionTag(updatedPermissionTag)
  }

  const handleFacilityChange = (selectedValue: any[]) => {
    setFacilityTag(selectedValue)
  }

  const handleFacilityDeleteTag = (id: number) => {
    const updatedFailityTag = [...facilityTag]
    updatedFailityTag.splice(id, 1)
    setFacilityTag(updatedFailityTag)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      className="flex flex-col"
      requiredMark={false}
      onFinish={onFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <div className="add-member-form-wrapper pb-4">
        <div className="flex flex-col  w-full">
          <div className="flex flex-row w-full gap-4">
            <div className="flex flex-col w-full">
              <InputField
                name="first_name"
                placeholder={COMMON.FIRST_NAME}
                rules={commonInputFieldValidation('your first name')}
                error={error.first_name}
                value={getValue('first_name')}
                onChange={(e) => {
                  setContactData({
                    ...contactData,
                    first_name: e.target.value,
                  })
                }}
              />
            </div>
            <div className="flex flex-col w-full">
              <InputField
                name="last_name"
                placeholder={COMMON.LAST_NAME}
                rules={commonInputFieldValidation('your last name')}
                error={error.last_name}
                value={getValue('last_name')}
              />
            </div>
          </div>
          <div className="flex flex-row w-full gap-4">
            {/* <div className="flex flex-col w-full"> */}
            <div className="flex gap-2 mobile-country-code w-full">
              <div className="country-code-wrapper w-120 h-64">
                <div className="country-flag">
                  <UsaIcon />
                </div>
                <p className="para-p2 font-normal text-neutral-800 ">{usa}</p>
              </div>
              <div className="flex flex-col w-full ">
                <InputField name="mobile_no" rules={mobileValidations} placeholder={COMMON.MOBILE_NO} error={error.mobile_no} wrapperClass="w-full" value={getValue('mobile_no')} />
              </div>
            </div>
            {/* </div> */}
            <div className="flex flex-col w-full">
              <InputField name="email" disabled={!!editId} placeholder={COMMON.EMAIL} rules={emailValidations} error={error.email} value={getValue('email')} />
            </div>
          </div>
          <div className="flex flex-row w-full gap-4 mb-6">
            <div className="flex flex-col w-full relative">
              <div className={`cursor-pointer relative ${permissionTag?.length > 0 ? 'mb-4' : 'mb-6'}`}>
                <CustomMultiSelect
                  name="permissions"
                  handleChange={handlePermissionChange}
                  Options={permissionOption}
                  placeholder={FACILITY_CONTACTS.Select_PERMISSION}
                  rules={commonSelectFieldValidation('permission')}
                  value={permissionTag}
                  error={error.permissions}
                />
              </div>
            </div>
          </div>
        </div>
        {permissionTag?.length > 0 && (
          <div className="flex flex-col gap-4 w-full mb-6">
            {permissionTag?.length > 0 && <HeadingText text={FACILITY_CONTACTS.PERMISSIONS} classString="para-p4 text-neutral-400" />}
            <div className="flex gap-4 w-full flex-wrap">
              {permissionTag?.map((tagId: string, index: number) => {
                const option = permissionOption.find((item) => item.id === tagId)
                return (
                  <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                    {option?.name}
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleDeleteTag(index)
                      }}
                    >
                      {<CloseIcon />}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <div className={`cursor-pointer`}>
          <CustomMultiSelect
            searchPlaceholder={COMMON.SEARCH_FACILITY}
            isIcon
            isSearch
            icon={<FacilityIcon />}
            name="facility_id"
            handleChange={handleFacilityChange}
            Options={failityAccessOption}
            placeholder={FACILITY_CONTACTS.FACILITY_ACCESS}
            rules={commonSelectFieldValidation('facility access')}
            value={facilityTag}
            error={error.facility_id}
          />
        </div>
        {facilityTag?.length > 0 && (
          <div className="flex gap-4 w-full mt-6 flex-wrap">
            {facilityTag?.map((tagId: string, index: number) => {
              const option = failityAccessOption.find((item) => item.id === tagId)
              return (
                <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                  {option?.name}
                  <span
                    className="flex items-center cursor-pointer"
                    onClick={() => {
                      handleFacilityDeleteTag(index)
                    }}
                  >
                    {<CloseIcon />}
                  </span>
                </div>
              )
            })}
          </div>
        )}
      </div>

      <div className="flex justify-between mt-4">
        <div className="flex gap-2 toggle-switch">
          <span>{FACILITY_CONTACTS.USER_STATUS}</span> <Switch value={statusCheck} onChange={onSwitchChange} />
        </div>

        <div className="flex gap-4">
          <CustomButton type="default" text={BUTTON_STRING.CANCEL} className="w-160 h-49" onClick={handleCancel} />
          <CustomButton htmlType="submit" text={editId ? BUTTON_STRING.UPDATE : BUTTON_STRING.ADD} type="primary" className="w-160 h-49" />
        </div>
      </div>
    </Form>
  )
}

export default AddMember
