import { type CSSProperties, type ReactNode } from 'react'
import { Modal } from 'antd'

import './CustomModal.scss'

interface ICustomModal {
  title?: string
  open: boolean
  onOk?: () => void
  onCancel?: () => void
  okText?: string
  cancelText?: string
  content: ReactNode
  showCancelBtn?: string
  width?: number
  className?: string
  footer?: ReactNode | null
  closable?: boolean
  closeIcon?: ReactNode
  bodyStyle?: CSSProperties
}

const CustomModal = ({ title, open, onOk, onCancel, closable, okText, cancelText, content, showCancelBtn, width, className, footer, closeIcon, bodyStyle }: ICustomModal) => {
  return (
    <Modal
      title={title}
      closable={closable}
      open={open}
      centered
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      footer={footer}
      closeIcon={closeIcon}
      maskClosable
      cancelText={cancelText}
      cancelButtonProps={{ style: { display: showCancelBtn } }}
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      className={`custom-modal ${className}`}
      width={width}
      // eslint-disable-next-line
      bodyStyle={bodyStyle}
    >
      {content}
    </Modal>
  )
}
export default CustomModal
