import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { http, methods, service } from 'service/Service'

import { type IAddMember } from '../types'

export const GET_BILLING_CONTACTS = async (id: string, search: string, order: string, field: string) =>
  await service({
    url: `/facility/billing-contacts/${id}?${search && `search=${search}`}${order && field && `&order[${field}]=${order}`}`,
    method: methods.GET,
  })

export const GET_FACILITY_CONTACTS = async (id: string, search: string, order: string, field: string, page = 1, limit = 5) => {
  const offset = (page - 1) * limit
  const url = `/facility/all-contacts/${id}?limit=${limit}&offset=${offset}${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}`
  return await service({ url, method: methods.GET })
}

export const EDIT_USER_CONTACT = async (id: string, payload: IAddMember) => {
  try {
    const response = await http.patch(`/facility/edit-contact/${id}`, payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const ADD_USER = async (payload: IAddMember) => {
  try {
    const response = await http.post(`/facility/add-contacts`, payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_CONTACT_PROFILE = async (id: string) => {
  try {
    const response = await http.get(`/facility-user/contact-profile/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_FACILITY_PERMISSION = async () => {
  try {
    const response = await http.get('/dropdown/facility-permission')
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_FACILITY_ACCESS = async () => {
  try {
    const response = await http.get('/dropdown/facility')
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const SEND_INVITATION = async (payload: { email: string }) => {
  try {
    const response = await http.patch('/auth/send-invitation/facility_user', payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
