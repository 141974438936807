export const ADMIN_CONTACT_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'in_active',
  INVITED: 'invited',
}

export const ADMIN_CONTACT_INVITATION_STATUS = {
  ACCEPTED: 'accepted',
  INVITED: 'invited',
}

export const SHIFT_STATUS = {
  OPEN: 'open',
  CANCELLED: 'cancelled',
  REQUESTED: 'requested',
  UNCONFIRMED: 'unconfirmed',
  UNSUBMITTED: 'un_submitted',
  SCHEDULE: 'scheduled',
  COMPLETED: 'completed',
  AUTO_SCHEDULING: 'auto_scheduling',
  ON_GOING: 'ongoing',
  VOID: 'void',
  RUNNING_LATE: 'running_late',
}

export const SHIFT_POP_UP_STATUS = {
  WITHDRAWN: 'withdrawn',
  INVITED: 'invited',
  REJECTED: 'rejected',
  UNSEEN: 'unseen',
}

export const PERIOD_STATUS = {
  EVENING: 'evening',
  DAY: 'day',
  NIGHT: 'night',
  FLEXIBLE: 'flexible',
}

export const SHIFT_INVITATION_STATUS = {
  WITHDRAWN: 'withdrawn',
  ACCEPTED: 'accepted',
  INVITED: 'invited',
  UNSEEN: 'unseen',
  REJECTED: 'rejected',
}

export const SHIFT_INVITATION_STATUS_COLOR: any = {
  withdrawn: { bgColor: `#fdf4f5`, color: `#d1293d` },
  rejected: { bgColor: `#fdf4f5`, color: `#d1293d` },
  invited: { bgColor: `#F2F0FE`, color: `#220EB1` },
  unseen: { bgColor: `#FFF8F0`, color: `#FB8600` },
}

export const TIME_AND_ATTENDANCE_STATUS = {
  APPROVED: 'approved',
  UNAPPROVED: 'un_approved',
  REJECTED: 'rejected',
}

export const flagsData = [
  { name: 'Preferred', type: 'preferred', color: '#359766' },
  { name: 'DNR', type: 'dnr', color: '#DC2626' },
]
