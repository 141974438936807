import { useState } from 'react'
import { Select } from 'antd'
import { ActivityClockIcon, CupIcon, DownIcon } from 'assets/svgs'
import HeadingText from 'component/HeadingText'
import { COMMON, SHIFTS } from 'constants/Constants'
import { SHIFT_STATUS } from 'constants/ConstantStatus'

import { type IActivityTabProps } from '../types'

const dayData = [
  {
    id: 1,
    value: 'Today',
  },
  {
    id: 2,
    value: 'Tomorrow',
  },
  {
    id: 3,
    value: 'Yesterday',
  },
]
const timeData = [
  {
    id: 1,
    value: 'Time Activity',
  },
  {
    id: 2,
    value: 'Facility',
  },
  {
    id: 3,
    value: 'Provider',
  },
]
const mockData = [
  {
    id: 1,
    icon: <ActivityClockIcon />,
    name: 'Catherine James',
    value: 'Clocked in for the shift.',
    time: '08:00 am',
  },
  {
    id: 2,
    icon: <CupIcon />,
    name: 'Catherine James',
    value: 'Took a break',
    time: '12:00 pm',
  },
  {
    id: 3,
    icon: <ActivityClockIcon />,
    name: 'Catherine James',
    value: 'Clocked out for the shift.',
    time: '03:00 pm',
  },
]

const ActivityFeed = ({ shiftStatus }: IActivityTabProps) => {
  const [status, setStatus] = useState(true)
  const [dayTag, setDayTag] = useState<any[]>([])
  const [timeTag, setTimeTag] = useState<any[]>([])
  const handleDayChange = (selectedValue: any[]) => {
    setDayTag(selectedValue)
  }
  const handleTimeChange = (selectedValue: any[]) => {
    setTimeTag(selectedValue)
  }
  return (
    <div className="activity-container flex w-40 flex-col items-start gap-6 self-stretch border-l-stroke">
      <div className="activity-bar-wrapper flex justify-between items-center self-stretch">
        <p className="text-neutral-800 mobile-heading-h5 font-medium">{SHIFTS.ACTIVITY}</p>
        <div className="day-select">
          <Select disabled={SHIFT_STATUS.VOID === shiftStatus} value={[]} onChange={handleDayChange} suffixIcon={<DownIcon />} options={dayData} placeholder={COMMON.TODAY} />
          <Select disabled={SHIFT_STATUS.VOID === shiftStatus} value={[]} onChange={handleTimeChange} suffixIcon={<DownIcon />} options={timeData} placeholder={COMMON.TIME_ACTI} />
        </div>
      </div>
      {!status ? (
        <div className="no-activity-wrapper flex w-full px-6 flex-col justify-center items-center gap-2 ">
          <p className="text-neutral-800 desktop-heading-h5 font-semi-bold">{SHIFTS.NO_ACTIVITY}</p>
          <p className="text-neutral-600 para-p1 font-light text-center">{SHIFTS.NO_ACTIVITY_SUB}</p>
        </div>
      ) : (
        <div className="activity-wrapper flex flex-col items-start gap-6 flex-1 self-stretch">
          <p className="para-p3 font-normal text-neutral-400">12TH DECEMBER, 2023</p>
          <div className="w-full">
            {mockData.map((item) => {
              return (
                <div key={item.id} className="flex justify-between">
                  <div>
                    <div className="flex gap-3 items-center">
                      <div className="flex items-center justify-center icon-wrapper border-stroke">{item.icon}</div>
                      <p className="text-neutral-800 flex gap-2 para-p3">
                        {item.name}
                        <span className="ml-2 text-neutral-600 font-normal para-p3">{item.value}</span>
                      </p>
                    </div>
                    {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                    <div className={`${mockData.length !== item.id && 'activity-dot full-height-line'}`} />
                  </div>
                  <HeadingText text={item.time} classString="text-neutral-500 font-small para-p3" />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default ActivityFeed
