import { useEffect, useRef } from 'react'
import { useAppSelector } from 'hooks/reduxHooks'
import { io, type Socket } from 'socket.io-client'

const useSocket = () => {
  const SOCKET_URL = process.env.REACT_APP_SOCKET_ENDPOINT as string
  const accessToken = useAppSelector((state) => state.auth.auth.accessToken)
  const socketRef = useRef<Socket | null>(null)

  useEffect(() => {
    if (accessToken && SOCKET_URL && !socketRef.current) {
      // Initialize the socket connection
      const socket = io(SOCKET_URL, {
        withCredentials: true,
        transports: ['websocket'],
        auth: {
          authorization: accessToken,
        },
      })

      socket.on('connect', () => {
        console.log('Socket connected:', new Date().toISOString())
      })

      socket.on('disconnect', () => {
        console.log('Socket disconnected:', new Date().toISOString())
      })

      socketRef.current = socket

      // Cleanup function to disconnect the socket
      return () => {
        if (socketRef.current) {
          socketRef.current.disconnect()
          socketRef.current = null
        }
      }
    }
  }, [accessToken, SOCKET_URL])

  return socketRef.current
}

export default useSocket
