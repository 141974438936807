import { useEffect, useState } from 'react'
import { Dropdown, Image, Input, type MenuProps, message, Select } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { facilityIcon } from 'assets/imgs'
import { DownIcon, SearchIcon } from 'assets/svgs'
import { FACILITY_USER_PROFILE } from 'constants/Constants'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { GET_FACILITY } from 'service/api/Common'
import { setFacilityID, setSelectedFacilityData } from 'store/slice/AuthSlice'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IFacility, IFacilityData } from 'types'
import { userToken } from 'utils/utils'

const { Option } = Select

const SwitchFacility = ({ collapsed }: { collapsed: boolean }) => {
  const [search, setSearch] = useState<string>('')
  const defaultFacility = useAppSelector((state) => state.auth.auth.defaultFacility)
  const [selectedFacilityId, setSelectedFacilityId] = useState('')
  const [facilityList, setFacilityList] = useState<IFacilityData[]>([])
  const [selectedFacilityImgSrc, setSelectedFacilityImgSrc] = useState('')
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (defaultFacility) {
      setSelectedFacilityId(defaultFacility)
    }
  }, [defaultFacility, dispatch])

  const handleFacility = (e: any) => {
    dispatch(setFacilityID(e?.value))
  }

  useEffect(() => {
    if (userToken()) {
      void handleGetAllFacility()
    }
  }, [userToken()])

  useEffect(() => {
    const selectedFacility = facilityList?.find((property: IFacilityData) => property?.id === selectedFacilityId)
    dispatch(setSelectedFacilityData(selectedFacility))
    const imgSrc = `${String(selectedFacility?.image ? `${selectedFacility?.base_url}${selectedFacility?.image}` : facilityIcon)}`
    setSelectedFacilityImgSrc(imgSrc)
    // eslint-disable-next-line
  }, [selectedFacilityId, location?.pathname])

  const handleGetAllFacility = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_FACILITY()
      if (response?.status === 200) {
        setFacilityList(response?.data?.data)
        if (!defaultFacility) {
          const masterFacility = response?.data?.data?.find((facility: IFacility) => facility?.is_master)
          setSelectedFacilityId(masterFacility ? masterFacility?.id : response?.data?.data[0]?.id)
          dispatch(setFacilityID(masterFacility ? masterFacility?.id : response?.data?.data[0]?.id))
          // eslint-disable-next-line no-unneeded-ternary
          dispatch(setSelectedFacilityData(masterFacility ? masterFacility : response?.data?.data[0]))
        }
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
  }

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
    }
  }

  const handleMenuClick = (id: string) => {
    setSelectedFacilityId(id)
    dispatch(setFacilityID(id))
  }

  const filteredFacilityList = facilityList?.filter((facility) => facility?.name?.toLowerCase()?.includes(search))

  const items: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <div className="gap-2 flex flex-col">
          <div className="flex justify-between items-center cursor-pointer gap-2 w-full flex-col">
            <div className={`flex flex-col items-center gap-2`}>
              {filteredFacilityList?.map((data: any) => {
                return (
                  <div
                    className={`anchor-menu-wrapper justify-between  ${!data?.is_master ? 'bg-white' : ''} `}
                    key={data?.id}
                    onClick={() => {
                      handleMenuClick(data?.id)
                    }}
                  >
                    <p className="text-neutral-800 para-p1">{data?.name}</p>
                    {data?.is_master && (
                      <div className="menu-badge-wrapper">
                        <p className="menu-badge-text">MASTER</p>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      ),
    },
  ]

  return (
    <div className="sider-facility-profile-box">
      {!collapsed && (
        <Select
          labelInValue
          onSelect={handleFacility}
          optionLabelProp="label"
          placeholder=""
          suffixIcon={null}
          value={selectedFacilityId}
          rootClassName="switch-facility-dropdown"
          dropdownStyle={{ width: '500px', justifyContent: 'flex-start' }}
          dropdownRender={(menu) => (
            <div>
              {filteredFacilityList.length >= 10 && (
                <div className="anchor-search-wrapper flex py-4 justify-between h-64 items-center">
                  <Input
                    placeholder={FACILITY_USER_PROFILE.SWITCH_FACILITY}
                    onPressEnter={() => {
                      onSearch(search)
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value)
                    }}
                    allowClear
                    className="custom-searchbar"
                    onKeyDown={onInputKeyDown}
                  />
                  <div className="search-icon">
                    <SearchIcon />
                  </div>
                </div>
              )}
              {menu}
            </div>
          )}
        >
          {filteredFacilityList?.map((item: IFacilityData) => {
            return (
              <Option
                key={item.id}
                value={item.id}
                label={
                  <>
                    <div className="flex gap-2 items-center justify-between w-full">
                      <Image
                        src={item?.image && item?.base_url ? `${item?.base_url}${item?.image}` : facilityIcon}
                        alt=""
                        preview={false}
                        width="48px"
                        height="48px"
                        className="min-h-48 min-w-48 w-20 rounded-3"
                      />
                      <div className="mb-2 w-60 flex flex-col">
                        <p className="para-p3 font-medium text-neutral-800 line-clamp-table">{item.name}</p>
                        <p className="para-p4 font-medium text-neutral-500">{item?.facility_type}</p>
                      </div>
                      <div className="down-icon-wrapper">
                        <DownIcon className="cursor-pointer facility-switch-down-icon" />
                      </div>
                    </div>
                  </>
                }
              >
                <div className="gap-2 flex flex-col justify-start">
                  <div className="w-full gap-2  flex justify-start items-center cursor-pointer flex-col">
                    <div className={`flex w-full flex-col items-start justify-start gap-2`}>
                      <div className="anchor-menu-wrapper justify-between items-start w-full" key={item.id}>
                        <p className="text-neutral-800 para-p1">{item?.name}</p>
                        {item.is_master && (
                          <div className="menu-badge-wrapper">
                            <p className="menu-badge-text">MASTER</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Option>
            )
          })}
        </Select>
      )}
      {collapsed && (
        <Dropdown rootClassName="facility-profile-dropdown" menu={{ items }} trigger={['click']}>
          <div className="flex items-center justify-center">
            <Image preview={false} src={selectedFacilityImgSrc} className="img-profile-picture" />
          </div>
        </Dropdown>
      )}
    </div>
  )
}

export default SwitchFacility
