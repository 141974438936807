import { methods, service } from 'service/Service'

export const GET_ALL_PROVIDER_LIST = async (
  limit = 10,
  current = 1,
  search: string,
  order: string,
  field: string,
  certificateId: string,
  specialityId: string,
  flag: string,
  facilityId: string
) => {
  const offset = (current - 1) * limit
  const url = `/facility-provider/all/${facilityId}?limit=${limit}&offset=${offset}${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}${certificateId && `&certificate[0]=${certificateId}`}${specialityId && `&speciality[0]=${specialityId}`}${flag && `&flag[0]=${flag}`}`
  return await service({ url, method: methods.GET })
}

export const UPDATE_FLAG_AS_DNR = async (id: string, payload: any) => {
  const url = `/facility-provider/dnr/${id}`
  return await service({ url, method: methods.PATCH, data: payload })
}

export const UPDATE_PROVIDER_FLAG = async (id: string, payload: any) => {
  const url = `/facility-provider/update/${id}`
  return await service({ url, method: methods.PATCH, data: payload })
}

export const GET_PROVIDER_SUMMARY = async (id: string) => {
  const url = `/facility-provider/summary/${id}`
  return await service({ url, method: methods.GET })
}

export const GET_PROVIDER_DEATILS = async (id: string) => {
  const url = `/facility-provider/details/${id}`
  return await service({ url, method: methods.GET })
}

export const GET_SCHEDULED_SHIFT_CALENDAR = async (id: string, date: string) => {
  const url = `/facility-provider/calendar/${id}?date=${date}`
  return await service({ url, method: methods.GET })
}

export const GET_SHIFT_HISTROY = async (id: string, current = 1, limit = 10, order: string, field: string) => {
  const offset = (current - 1) * limit
  const url = `/facility-provider/shift/${id}?limit=${limit}&offset=${offset}${order && field && `&order[${field}]=${order}`}`
  return await service({ url, method: methods.GET })
}

export const GET_PROVIDER_CREDENTIAL = async (id: string, order: string, field: string) => {
  const url = `/facility-provider/credentials/${id}?${order && field && `order[${field}]=${order}`}`
  return await service({ url, method: methods.GET })
}
export const GET_ALL_FLAG = async () => {
  const url = `/dropdown/flag`
  return await service({ url, method: methods.GET })
}

export const getAllDnrReason = async (query: string) => {
  const url = `/dropdown/dnr-reason?${query}`
  return await service({ url, method: methods.GET })
}

export const requestToWork = async (id: string, payload: any) => {
  const url = `/shift/request-work/${id}`
  return await service({ url, method: methods.POST, data: payload })
}
