import { methods, service } from 'service/Service'

export const GET_CERTIFICATE = async (search?: string) => {
  const url = `/dropdown/certificate${search ? `?search=${encodeURIComponent(search)}` : ''}`
  return await service({ url, method: methods.GET })
}

export const GET_SPECIALITY = async (search?: string) => await service({ url: `/dropdown/speciality${search ? `?search=${encodeURIComponent(search)}` : ''}`, method: methods.GET })

export const GET_FACILITY = async () => await service({ url: '/dropdown/facility', method: methods.GET })

export const GET_NOTIFICATION = async () => await service({ url: '/user-notification?limit=0', method: methods.GET })

export const LOGOUT = async () => await service({ url: 'auth/logout', method: methods.POST })

export const GET_FACILITY_USER = async (id: string, search?: string) =>
  await service({ url: `/dropdown/facility-user/${id}${search ? `?search=${encodeURIComponent(search)}` : ''}`, method: methods.GET })
