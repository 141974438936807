import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { http, methods, service } from 'service/Service'

import type { ICancelShift, ICreateShift, IUpdateShift } from '../types'

export const GET_SHIFTS = async (
  facilityId: string,
  page = 1,
  limit = 10,
  search: string,
  order: string,
  field: string,
  certificate: string,
  speciality: string,
  fromDate: string,
  toDate: string,
  status: string[],
  query?: string
) => {
  const offset = (page - 1) * limit

  let url = `/shift/all/${facilityId}?offset=${offset}&limit=${limit}${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}${certificate && `&certificate[0]=${certificate}`}${speciality && `&speciality[0]=${speciality}`}${fromDate && `&from_date=${fromDate}`}${toDate && `&to_date=${toDate}`}&${query ?? ''}`
  status.forEach((status, index) => {
    url += `&status[${index}]=${status}`
  })
  return await service({ url, method: methods.GET })
}

export const CREATE_SHIFT = async (payload: ICreateShift) => {
  return await service({
    url: '/shift',
    method: methods.POST,
    data: payload,
  })
}

export const GET_UNPOSTED_SHIFTS = async (id: string) => {
  return await service({ url: `/shift/post/${id}`, method: methods.GET })
}

export const POST_SHIFT = async (payload: { shift: string[] }) => {
  return await service({
    url: '/shift/post',
    method: methods.PATCH,
    data: payload,
  })
}

export const CLONE_SHIFT = async (id: string) => {
  return await service({
    url: `/shift/clone/${id}`,
    method: methods.POST,
  })
}

export const DELETE_UNPOSTED_SHIFT = async (id: string) => {
  return await service({
    url: `/shift/un-posted/${id}`,
    method: methods.DELETE,
  })
}

export const UPDATE_SHIFT = async (id: string, payload: ICreateShift) => {
  return await service({
    url: `/shift/update/${id}`,
    method: methods.PATCH,
    data: payload,
  })
}

export const GET_SINGLE_SHIFT = async (id: string) => {
  return await service({ url: `/shift/detail/${id}`, method: methods.GET })
}

export const GET_CANCEL_REASON = async () => {
  return await service({ url: `/dropdown/shift-cancel-reason?type=facility`, method: methods.GET })
}

export const EDIT_POSTED_SHIFT = async (id: string, payload: IUpdateShift) => {
  return await service({
    url: `shift/update/${id}`,
    method: methods.PATCH,
    data: payload,
  })
}

export const GET_REQUESTED_USER = async (id: string, limit: number, offset: number) => {
  return await service({
    url: `/shift-request/all/${id}?limit=${limit}&offset=${offset}`,
    method: methods.GET,
  })
}

export const ASSIGN_USER_REQUEST = async (id: string) => {
  try {
    const response = await http.patch(`/shift-request/assign/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const REJECT_USER_REQUEST = async (id: string) => {
  return await service({
    url: `/shift-request/reject/${id}`,
    method: methods.DELETE,
  })
}

export const GET_ALL_INVITED_USER = async (id: string, limit: number, offset: number) => {
  return await service({
    url: `/shift-invitation/all/${id}?limit=${limit}&offset=${offset}`,
    method: methods.GET,
  })
}

export const INVITE_PROVIDER_AGAIN = async (id: string) => {
  return await service({
    url: `/shift-invitation/again/${id}`,
    method: methods.PATCH,
  })
}

export const WITHDRAWN_INVITATION = async (id: string) => {
  return await service({
    url: `/shift-invitation/withdrawn/${id}`,
    method: methods.PATCH,
  })
}

export const CANCEL_SHIFT = async (id: string, payload: ICancelShift) => {
  return await service({
    url: `/shift/cancel/${id}`,
    method: methods.PATCH,
    data: payload,
  })
}

export const ALL_UNPOSTED_SHIFT_DELETE = async (id: string) => {
  return await service({
    url: `/shift/un-posted/all/${id}`,
    method: methods.DELETE,
  })
}

export const GET_ALL_PROVIDER = async (filter: string, facility: string, search: string, certificate: string, speciality: string) => {
  let url = `/dropdown/provider?filter=${filter}&facility_id=${facility}&certificate_id=${certificate}&speciality_id=${speciality}`

  if (search) {
    url += `&search=${search}`
  }

  return await service({
    url,
    method: methods.GET,
  })
}

export const GET_AUTO_SCHEDULED_USER = async (id: string, limit: number, offset: number) => {
  return await service({
    url: `/shift-invitation/auto/${id}?limit=${limit}&offset=${offset}`,
    method: methods.GET,
  })
}

export const GET_SHIFT_TYPE = async () => {
  return await service({
    url: `/dropdown/shift-type`,
    method: methods.GET,
  })
}
