import React, { type ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { Form, Select } from 'antd'
import { type SearchProps } from 'antd/es/input'
import Search from 'antd/es/input/Search'
import { SearchIcon, SelectDownIcon } from 'assets/svgs'

import './CustomSelect.scss'

const { Option, OptGroup } = Select

interface IRules {
  required?: boolean
  message?: string
}

interface IOption {
  label: string | JSX.Element
  value: string
  htmlContent?: string
}

interface IOptionGroup {
  label: string
  title: string
  options: IOption[]
}

interface ISelect {
  wrapperClass?: string
  label: string
  options: Array<IOption | IOptionGroup>
  value?: string
  onChange?: (value: string) => void
  name: string
  prefixIcon?: React.ReactNode
  disabled?: boolean
  error?: string
  rules?: IRules[]
  searchPlaceholder?: string
  isSearch?: boolean
  reset?: boolean
  searchData?: (search: string) => void
}

const isOptionGroup = (option: IOption | IOptionGroup): option is IOptionGroup => {
  return (option as IOptionGroup).options !== undefined
}

const CustomSelect = ({ disabled, wrapperClass, label, name, prefixIcon, options, value, onChange, error, rules, searchPlaceholder, isSearch, reset, searchData }: ISelect) => {
  const [focused, setFocused] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [hasValue, setValue] = useState(false)

  const selectRef = useRef<any>(null)

  useEffect(() => {
    const element = selectRef?.current?.nativeElement
    if (element) {
      if (element?.innerText) {
        setFocused(true)
        setValue(true)
        return
      }
      element.addEventListener('focus', handleFocus)
    }
    return () => {
      if (element) {
        element.removeEventListener('focus', handleFocus)
      }
    }
  }, [selectRef.current])

  useEffect(() => {
    if (searchData) searchData(search)
  }, [search])

  useEffect(() => {
    if (reset) {
      setFocused(false)
      setValue(false)
    }
  }, [reset])

  useEffect(() => {
    if (value) {
      setFocused(true)
      setValue(true)
    } else {
      setFocused(false)
      setValue(false)
    }
  }, [value])

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    if (selectRef?.current?.nativeElement?.innerText !== '') {
      setFocused(true)
      setValue(true)
    } else {
      setFocused(false)
      setValue(false)
    }
  }

  const filteredOptions = options.map((option) => {
    if (isOptionGroup(option)) {
      return {
        ...option,
        options: option?.options?.filter((opt) => {
          const label = typeof opt.label === 'string' ? opt.label : opt.label.props.children
          return label.toLowerCase().includes(search.toLowerCase())
        }),
      }
    } else {
      return option
    }
  })

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
  }

  const handleSearchChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
    }
  }

  const onInputKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
    }
  }

  const handleChange = (newValue: string) => {
    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <span className={`custom-select ${wrapperClass as string}`}>
      <Form.Item className={`floating-label-select  ${error ? '' : 'mb-6'} `} name={name} rules={rules}>
        <Select
          ref={selectRef}
          disabled={disabled}
          onChange={handleChange}
          allowClear
          onFocus={handleFocus}
          onBlur={handleBlur}
          suffixIcon={<SelectDownIcon color={disabled ? '#919195' : 'black'} />}
          className={`select ${prefixIcon ? '' : 'no-icon'}`}
          placeholder=""
          showSearch={false}
          filterOption={(input, option: any) => option?.label?.toLowerCase().includes(input.toLowerCase())}
          dropdownRender={(menu) => (
            <div>
              {isSearch && (
                <div className="anchor-search-wrapper flex py-4 h-49 items-center">
                  <Search
                    placeholder={searchPlaceholder}
                    prefix={<SearchIcon />}
                    onSearch={onSearch}
                    allowClear
                    className="custom-searchbar"
                    onChange={(e) => {
                      handleSearchChange(e.target.value)
                    }}
                    onKeyDown={onInputKeyDown}
                  />
                </div>
              )}
              <div>{menu}</div>
            </div>
          )}
        >
          {filteredOptions.map((option) =>
            isOptionGroup(option) ? (
              <OptGroup key={option?.title} label={<span>{option?.label}</span>}>
                {option.options.map((opt) => (
                  <Option key={opt?.value} value={opt?.value}>
                    {typeof opt.label === 'string' ? <span>{opt.label}</span> : opt.label}
                  </Option>
                ))}
              </OptGroup>
            ) : (
              <Option key={option?.value} value={option?.value}>
                {typeof option.label === 'string' ? <span>{option.label}</span> : option.label}
              </Option>
            )
          )}
        </Select>
      </Form.Item>
      <label className={`${focused || hasValue ? 'focused filled' : ''} `}>
        {prefixIcon && <div className={`prefix-icon-wrapper ${disabled ? 'prefix-icon-disabled' : ''}`}>{prefixIcon}</div>}
        <span className={`floating-label ${prefixIcon ? '' : 'floating-icon-label'} `} style={{ color: disabled ? '#ABABAE !important' : '' }}>
          {label}
        </span>
      </label>
      {error && <p className="error-container">{error}</p>}
    </span>
  )
}

export default CustomSelect
