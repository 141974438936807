import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form } from 'antd'
import { DownIcon, LogoIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import PasswordField from 'component/PasswordField/PasswordField'
import { COMMON, SIGNUP } from 'constants/Constants'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { selectSignUp, setFormData } from 'store/slice/SignupDataSlice'
import { type IFieldData } from 'types'
import { confirmPwdValidation, newPwdValidation } from 'utils/Validations'

import { type ISetPassword } from './types'

import './SignUp.scss'

const SetPassword = () => {
  const dispatch = useAppDispatch()
  const formData = useAppSelector(selectSignUp)
  const navigate = useNavigate()

  const onFinish = (values: ISetPassword) => {
    const newPassValue = values.password
    dispatch(setFormData({ password: newPassValue }))
    navigate('/email-verification')
  }

  useEffect(() => {
    if (formData?.email === '') {
      navigate('/signup')
    }
  }, [formData, navigate])

  const [error, setError] = useState({
    password: '',
    confirm_password: '',
  })

  const handleFieldsChange = (allFields: IFieldData[]) => {
    const initialErrorState = { password: '', confirm_password: '' }

    const errorState = allFields.reduce((acc, field) => {
      const fieldName = field.name[0]
      if ((fieldName === 'password' || fieldName === 'confirm_password') && field.errors && field.errors.length > 0) {
        acc[fieldName] = field.errors[0]
        if (acc.password && acc.confirm_password) {
          return acc
        }
      }
      return acc
    }, initialErrorState)

    setError(errorState)
  }

  const handleBackClick = () => {
    navigate('/signup')
  }

  return (
    <div className="bg-neutral-50 h-screen relative">
      <div className="back-btn-wrapper flex justify-center items-center p-2 cursor-pointer" onClick={handleBackClick}>
        <DownIcon className="back-icon" />
        <p className="text-primary-500 para-p2">{COMMON.BACK}</p>
      </div>
      <div className="bg-neutral-50 mx-auto max-w-400 w-full pt-140 flex flex-col gap-10 justify-center items-center no-scroll">
        <div className="flex flex-col items-center">
          <LogoIcon />
          <p className="desktop-heading-h3 font-medium mt-6">{SIGNUP.SET_PASSWORD}</p>
          <p className="para-p2 font-normal text-neutral-500 text-center mt-1">{SIGNUP.SET_PASSWORD_SUB}</p>
        </div>

        <div className="w-full min-w-full">
          <Form initialValues={formData} layout="vertical" onFinish={onFinish} className="min-w-full" requiredMark={false} onFieldsChange={handleFieldsChange}>
            <PasswordField
              error={error.password}
              name="password"
              rules={newPwdValidation}
              placeholder={COMMON.NEW_PASSWORD}
              inputClass={'login-input password-input'}
              delay={100}
            />
            <PasswordField
              error={error.confirm_password}
              name="confirm_password"
              rules={confirmPwdValidation}
              placeholder={COMMON.CON_NEW_PASS}
              inputClass={'login-input password-input'}
            />

            <CustomButton text={COMMON.CONTINUE} htmlType="submit" />
          </Form>
        </div>
      </div>
    </div>
  )
}

export default SetPassword
