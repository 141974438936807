export const VALIDATION_ERROR = {
  SOMETHING: 'Something Went Wrong',
  SELECT_ONE: 'Select any one',
  INPUT_FIELD: 'Please enter',
  SINGLE_SELECT: 'Please select any',
  MULTI_SELECT: 'Please select at least one',
  ADD_CONTACT: 'Please Add Contact!',
  REASON: 'reason',
  COMMON: {
    PASSWORD: 'Please input your password!',
    EMAIL: 'Enter Email Address',
    EMAIL2: 'Enter valid email address',
    USERNAME: 'Enter username',
    USERNAME2: 'Username can only contain letters, numbers, and underscores',
    MOBILE: 'Enter mobile number',
    MOBILE2: 'Mobile can only contain number',
    MINNUMBER: 'Must be at least 8 characters',
    MAXNUMBER: 'Cannot exceed 14 characters',
    NAME2: 'Name can only contain letters, hyphens, apostrophes, and spaces',
    LAST_NAME_2: 'Last Name can only contain letters',
    FIRST_NAME_2: 'Last Name can only contain letters',
    INPUT_FIELD: 'Please enter',
    CITY: 'Enter your city!',
    SHIFT_DESCRIPTION: 'Enter shift Description',
    DESCRIPTION: 'Enter description',
    FLOOR: 'Select any Floor',
    REVIEW: 'Enter review',
    SIGNATURE_EMPTY: 'Please Add signature before prociding!',
  },
  IMAGE: {
    ONLYIMG: 'Only image files are allowed for upload.',
  },
  PASSWORD: {
    EMPTY_CURRENT_PASSWORD: 'Please input your current password!',
    EMPTY_CONFIRM_PASSWORD: 'Please input your confirm password!',
    EMPTY_NEW_PASSWORD: 'Please input your new password!',
    NOT_MATCH_PASSWORD: 'The passwords that you entered do not match.',
    MINIMUM_LENGTH: 'Password minimum 8 characters long',
    INVALID_PASSWORD: 'Include at least one number, one special character, one uppercase letter, and one lowercase letter.',
  },
  DATE_RANGE: 'Please select a valid date range',
  DATE: 'Please Select Date',
}
