import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, message } from 'antd'
import { DownIcon, LogoIcon, UsaIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import InputField from 'component/InputField/InputField'
import { COMMON, SIGNUP } from 'constants/Constants'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { selectSignUp, setFormData } from 'store/slice/SignupDataSlice'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { commonInputFieldValidation, mobileValidations } from 'utils/Validations'

import { signUp } from '../api'
import { type ISignupForm } from '../types'

const EmailVerification = () => {
  const formData = useAppSelector(selectSignUp)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [error, setError] = useState({
    mobile_no: '',
    name: '',
  })

  useEffect(() => {
    if (formData?.email === '' || formData.password === '') {
      navigate('/signup')
    }
  }, [formData, navigate])

  const onFinish = (values: { country_code: string; name: string; mobile_no: string }) => {
    dispatch(setGlobalLoader(true))

    const updatedValues: ISignupForm = {
      email: formData?.email,
      password: formData.password,
      name: values.name,
      mobile_no: values.mobile_no,
      country_code: SIGNUP.COUNTRY_CODE,
    }

    void handleSignup(updatedValues)
    dispatch(setFormData(updatedValues))
  }

  const handleSignup = async (data: ISignupForm) => {
    try {
      const response = await signUp(data)
      if (response?.data?.statusCode === 1) {
        void message.success(response?.data?.message)
        navigate('/email-verify')
      }
    } catch (error: any) {}
    dispatch(setGlobalLoader(false))
  }

  const handleBackClick = () => {
    navigate('/set-password')
  }

  return (
    <>
      <div className="bg-neutral-50 h-screen relative">
        <div className="back-btn-wrapper flex justify-center items-center p-2 cursor-pointer" onClick={handleBackClick}>
          <DownIcon className="back-icon" />
          <p className="text-primary-500 para-p2">{COMMON.BACK}</p>
        </div>
        <div className="bg-neutral-50 mx-auto max-w-400 w-full pt-140 flex flex-col gap-10 items-center">
          <div className="flex flex-col items-center">
            <LogoIcon />
            <p className="desktop-heading-h3 font-medium mt-6">{SIGNUP.FACILITY_NAME_AND_}</p>
            <p className="para-p2 font-normal text-neutral-500 text-center mt-1">{SIGNUP.EMAIL_VERI_SUB}</p>
          </div>

          <div className="w-full min-w-full">
            <Form
              layout="vertical"
              onFinish={onFinish}
              className="min-w-full"
              requiredMark={false}
              onFieldsChange={(allFields: IFieldData[]) => {
                handleFieldsChange(allFields, setError)
              }}
            >
              <InputField name="name" rules={commonInputFieldValidation('facility name')} placeholder={COMMON.FACILITY_NAME} error={error.name} />
              <div className="w-full item-center">
                <div className={`flex gap-2 mobile-country-code ${error.mobile_no ? 'h-88' : 'h-64'} `}>
                  <div className="country-code-wrapper w-120 h-64">
                    <div className="country-flag">
                      <UsaIcon />
                    </div>
                    <p className="para-p2 font-normal text-neutral-800 ">{SIGNUP.COUNTRY_CODE}</p>
                  </div>
                  <div className="flex flex-col flex-grow-1 ">
                    <InputField name="mobile_no" rules={mobileValidations} placeholder={COMMON.MOBILE_NO} wrapperClass="w-full flex-grow-1" error={error.mobile_no} />
                  </div>
                </div>
              </div>
              <CustomButton text={COMMON.CONTINUE} htmlType="submit" className="mt-6" />
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailVerification
