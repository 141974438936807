import React, { useCallback, useEffect, useState } from 'react'
import { Dropdown, Form, type MenuProps, message } from 'antd'
import type { SearchProps } from 'antd/es/input'
import type { ColumnsType } from 'antd/es/table'
import { CloseIcon, DatePickerIcon, EditPenIcon, PlusIcon, RefreshIcon, RequestIcon, ThreeDotIcon, TimeCardIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import CustomModal from 'component/CustomModal/CustomModal'
import CustomDatePickerField from 'component/DatePicker/CustomDatePicker'
import HeadingText from 'component/HeadingText'
import CustomMultiSelect from 'component/Select/CustomMultiSelect'
import CustomSelect from 'component/Select/CustomSelect'
import CustomTable from 'component/Table/CustomTable'
import { BUTTON_STRING, COMMON, PAGE_TITLE_STRING, SHIFTS } from 'constants/Constants'
import { SHIFT_STATUS } from 'constants/ConstantStatus'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { GET_CERTIFICATE, GET_SPECIALITY } from 'service/api/Common'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFacility } from 'types'
import { formatTitle } from 'utils/helper'

import { GET_SHIFTS } from './api'
import CancelShift from './CancelModal'
import CreateShift from './CreateShift'
import DetailShift from './DetailShift'
import { type IFilterTable } from './types'

dayjs.extend(customParseFormat)

const statusOption = [
  {
    value: 'open',
    name: 'Open',
  },
  {
    value: 'requested',
    name: 'Requested',
  },
  {
    value: 'scheduled',
    name: 'Scheduled',
  },
  {
    value: 'ongoing',
    name: 'Ongoing',
  },
  {
    value: 'running_late',
    name: 'Running Late',
  },
  {
    value: 'unconfirmed',
    name: 'Unconfirmed  ',
  },
  {
    value: 'cancelled',
    name: 'Cancelled',
  },
  {
    value: 'completed',
    name: 'Completed',
  },
  {
    value: 'un_submitted',
    name: 'Unsubmitted',
  },
  {
    value: 'void',
    name: 'Void',
  },
]

const Shifts = () => {
  const [isCancel, setIsCancel] = useState<boolean>(false)
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)
  const [shiftData, setShiftData] = useState([])
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [isCreateShiftModalOpen, setIsCreateShiftModalOpen] = useState<boolean>(false)
  const [rowId, setRowId] = useState<string>('')
  const [tabId, setTabId] = useState<string>('1')
  const [limit, setLimit] = useState(10)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isOpenCancelModal, setIsCancelModal] = useState<boolean>(false)
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  // eslint-disable-next-line
  const [selectedRowData, setSelectedRowData] = useState<any>()
  const isFacility = useAppSelector((state) => state?.auth?.auth?.profileDetails?.isFacility)
  const facilityId = useAppSelector((state) => state?.auth?.auth?.defaultFacility)
  const [selectedValue, setSelectedValue] = useState<string | undefined>()
  const [search, setSearch] = useState<string>('')
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const [filterTable, setFilterTable] = useState<IFilterTable>({
    certificate: '',
    speciality: '',
    status: [],
    from_date: '',
    to_date: '',
  })
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [certificateData, setCertificateData] = useState<IFacility[]>([])
  const [certificareOriginData, setCertificateOriginData] = useState<IFacility[]>([])
  const [specialityData, setSpecialityData] = useState<IFacility[]>([])
  const [specialityOriginData, setSpecialityOriginData] = useState<IFacility[]>([])
  const [statusTag, setStatusTag] = useState<string[]>([])
  const [filterApply, setFilterApply] = useState(false)

  useEffect(() => {
    setTotal(0)
    setCurrent(1)
    setLimit(10)
  }, [facilityId])

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: PAGE_TITLE_STRING.SHIFTS,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetShiftData(
      current,
      limit,
      sortTable?.order,
      sortTable?.field,
      filterTable?.certificate,
      filterTable?.speciality,
      filterTable?.from_date,
      filterTable?.to_date,
      filterTable?.status
    )
  }, [current, limit, sortTable, facilityId, search, isCancel, !isCancel, isCreateShiftModalOpen, !isEdit, selectedValue === 'per_diem'])

  useEffect(() => {
    if (filterApply) {
      if (current !== 1) {
        setCurrent(1)
      } else {
        void handleGetShiftData(
          current,
          limit,
          sortTable?.order,
          sortTable?.field,
          filterTable?.certificate,
          filterTable?.speciality,
          filterTable?.from_date,
          filterTable?.to_date,
          filterTable?.status
        )
      }
    }
  }, [filterApply])

  useEffect(() => {
    if (isDrawerOpen) {
      void getCertificate()
      void getSpeciality()
    }
  }, [isDrawerOpen])

  const handleGetShiftData = async (
    page: number,
    pageSize: number,
    order: string,
    field: string,
    certificate: string,
    speciality: string,
    fromDate: string,
    toDate: string,
    status: string[]
  ) => {
    dispatch(setGlobalLoader(true))
    try {
      let response
      if (selectedValue === 'per_diem') response = await GET_SHIFTS(facilityId, page, pageSize, search, order, field, certificate, speciality, fromDate, toDate, status)
      if (response?.data?.statusCode === 1) {
        setShiftData(response?.data?.data)
        setTotal(selectedValue === 'per_diem' ? response?.data?.total : 0)
        setFilterApply(false)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const getCertificate = async () => {
    try {
      const response = await GET_CERTIFICATE()
      if (response?.status === 200) {
        setCertificateData(response?.data?.data)
        setCertificateOriginData(response?.data?.data)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const getSpeciality = async () => {
    try {
      const response = await GET_SPECIALITY()
      if (response?.status === 200) {
        setSpecialityData(response?.data?.data)
        setSpecialityOriginData(response?.data?.data)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const handleCancelClick = (id: string) => {
    setIsCancelModal(true)
    setRowId(id)
  }

  const handleChangePage = (page: number, pageSize?: number) => {
    setCurrent(page)
    if (pageSize) setLimit(pageSize)
  }

  const handleDetailModal = (record: { id: string }) => {
    setRowId(record?.id)
    setIsDetailModalOpen(true)
  }

  const getMenu = (record: any): MenuProps => {
    return {
      items: [
        {
          key: 1,
          label: (record.status === SHIFT_STATUS.REQUESTED ||
            record.status === SHIFT_STATUS.SCHEDULE ||
            record.status === SHIFT_STATUS.UNSUBMITTED ||
            record.status === SHIFT_STATUS.ON_GOING ||
            record.status === SHIFT_STATUS.VOID) && (
            <div
              className="p-4 cursor-pointer w-full"
              onClick={() => {
                setIsDetailModalOpen(true)
                setRowId(record?.id)
                if (record?.status === SHIFT_STATUS.REQUESTED || record?.status === SHIFT_STATUS.SCHEDULE) {
                  setTabId('3')
                } else {
                  setTabId('1')
                }
              }}
            >
              <p className="flex items-center gap-2">
                <RequestIcon />
                <span className="mobile-heading-h6 font-normal text-neutral-800">
                  {record.status === SHIFT_STATUS.UNSUBMITTED || record.status === SHIFT_STATUS.ON_GOING || record.status === SHIFT_STATUS.VOID
                    ? SHIFTS.VIEW_DETAILS
                    : SHIFTS.VIEW_REQUEST}
                </span>
              </p>
            </div>
          ),
        },
        {
          label: record?.status !== SHIFT_STATUS.OPEN &&
            record?.status !== SHIFT_STATUS.REQUESTED &&
            record?.status !== SHIFT_STATUS.UNCONFIRMED &&
            record?.status !== SHIFT_STATUS.AUTO_SCHEDULING &&
            record?.status !== SHIFT_STATUS.SCHEDULE &&
            record?.status !== SHIFT_STATUS.UNSUBMITTED &&
            record?.status !== SHIFT_STATUS.ON_GOING &&
            record?.status !== SHIFT_STATUS.CANCELLED &&
            record.status !== SHIFT_STATUS.VOID && (
              <div
                className="p-4 cursor-pointer w-full"
                onClick={() => {
                  setRowId(record?.id)
                  setIsCreateShiftModalOpen(true)
                }}
              >
                <p className="flex items-center gap-2">
                  <RefreshIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.REPOST}</span>
                </p>
              </div>
            ),
          key: 2,
        },
        {
          label: record.status === SHIFT_STATUS.CANCELLED && (
            <div className="p-4 cursor-pointer w-full">
              <p className="flex items-center gap-2">
                <RefreshIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.REOPEN}</span>
              </p>
            </div>
          ),
          key: 3,
        },
        {
          label: record.status === SHIFT_STATUS.COMPLETED && (
            <div className="p-4 cursor-pointer w-full">
              <p className="flex items-center gap-2">
                <TimeCardIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.VIEW_TIMECARD}</span>
              </p>
            </div>
          ),
          key: 4,
        },
        {
          label: record.status !== SHIFT_STATUS.CANCELLED &&
            record.status !== SHIFT_STATUS.COMPLETED &&
            record.status !== SHIFT_STATUS.UNCONFIRMED &&
            record.status !== SHIFT_STATUS.AUTO_SCHEDULING &&
            record.status !== SHIFT_STATUS.UNSUBMITTED &&
            record.status !== SHIFT_STATUS.ON_GOING &&
            record.status !== SHIFT_STATUS.VOID && (
              <div
                className="p-4 cursor-pointer w-full"
                onClick={() => {
                  setIsEdit(true)
                  setIsDetailModalOpen(true)
                  setRowId(record?.id)
                }}
              >
                <p className="flex items-center gap-2">
                  <EditPenIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.EDIT_SHIFT}</span>
                </p>
              </div>
            ),
          key: 5,
        },
        {
          label: record.status !== SHIFT_STATUS.CANCELLED &&
            record.status !== SHIFT_STATUS.COMPLETED &&
            record.status !== SHIFT_STATUS.UNSUBMITTED &&
            record.status !== SHIFT_STATUS.ON_GOING &&
            record.status !== SHIFT_STATUS.VOID && (
              <div
                className="p-4 cursor-pointer w-full"
                onClick={() => {
                  handleCancelClick(record?.id)
                }}
              >
                <p className="flex items-center gap-2">
                  <CloseIcon /> <span className="para-p1 font-normal text-neutral-800">{BUTTON_STRING.CANCEL_SHIFT}</span>
                </p>
              </div>
            ),
          key: 6,
        },
      ],
    }
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setCurrent(1)
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setCurrent(1)
    }
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const columns: ColumnsType = [
    {
      title: SHIFTS.TABLE.SHIFT_ID,
      dataIndex: 'shift_id',
      key: 's.shift_id',
      align: 'center',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: SHIFTS.TABLE.SHIFT_DATE,
      dataIndex: 'start_date',
      key: 's.start_date',
      align: 'center',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return <p>{dayjs(record?.start_date).format('MM/DD/YYYY')}</p>
      },
    },
    {
      title: SHIFTS.TABLE.SHIFT_TIME,
      dataIndex: 's.start_time',
      key: 's.start_time',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 350,
      render: (_value, record) => {
        // eslint-disable-next-line
        return <p className="status-text">{`${dayjs(record?.start_time, 'HH:mm:ss').format('h:mm A')} - ${dayjs(record?.end_time, 'HH:mm:ss').format('h:mm A')}`}</p>
      },
    },
    {
      title: SHIFTS.TABLE.LICENSE,
      dataIndex: 'c.name',
      key: 'c.name',
      align: 'center',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <HeadingText
              classString="status"
              style={{ background: record?.certificate?.background_color, color: record?.certificate?.text_color }}
              text={record?.certificate?.name}
            />
          </div>
        )
      },
    },
    {
      title: SHIFTS.TABLE.SPECIALTIES,
      dataIndex: 'sp.name',
      key: 'sp.name',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 200,
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <HeadingText style={{ background: record?.speciality?.background_color, color: record?.speciality?.text_color }} classString="status" text={record?.speciality?.name} />
          </div>
        )
      },
    },
    {
      title: SHIFTS.TABLE.STATUS,
      dataIndex: 's.status',
      key: 's.status',
      align: 'center',
      width: 250,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return (
          <div className="justify-center flex">
            <HeadingText
              classString={`common-tags ${record.status === SHIFT_STATUS.OPEN ? 'tertiary' : record.status === SHIFT_STATUS.CANCELLED ? 'error' : record.status === SHIFT_STATUS.COMPLETED ? 'neutral-tag' : record.status === SHIFT_STATUS.SCHEDULE ? 'success' : record.status === SHIFT_STATUS.VOID ? 'neutral-tag' : 'warning'}`}
              text={formatTitle(record?.status)}
            />
          </div>
        )
      },
    },
    {
      title: SHIFTS.TABLE.NO_OF_REQUEST,
      dataIndex: 'total_requests',
      key: 'total_requests',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      align: 'center',
      width: 200,
    },
    {
      title: COMMON.ACTION,
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 120,
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <Dropdown
              rootClassName={`p-4 action-menu-dropdown action-dropdown`}
              menu={getMenu(record)}
              trigger={['click']}
              onOpenChange={() => {
                setSelectedRowData(record)
              }}
            >
              <CustomButton isIcon svgIcon={<ThreeDotIcon className="cursor-pointer user-icon" />} text="" className="p-1-imp w-fit h-fit three-dot-icon" type={'default'} />
            </Dropdown>
          </div>
        )
      },
    },
  ]
  const handleCreateShift = () => {
    setIsCreateShiftModalOpen(true)
  }

  const tableButton = !isFacility && (
    <CustomButton isIcon svgIcon={<PlusIcon />} text={SHIFTS.CREATE_SHIFT} onClick={handleCreateShift} type="primary" className="rounded-3 border-0" />
  )

  const tabData = [
    { value: 'per_diem', label: 'Per Diem' },
    { value: 'long_term', label: 'Long Term' },
  ]

  const getValue = useCallback(
    (fieldName: string) => {
      return form.getFieldValue(fieldName)
    },
    [isDrawerOpen, form]
  )

  const handleDrawerOpenChange = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen)
    if (isOpen) {
      form.setFieldsValue({
        ...filterTable,
        license: filterTable?.certificate,
        from_date: filterTable?.from_date ? dayjs(filterTable?.from_date) : '',
        to_date: filterTable?.to_date ? dayjs(filterTable?.to_date) : '',
      })
      setStatusTag(filterTable?.status || [])
    }
  }

  const applyFilter = (value?: { license?: string; speciality?: string; from_date?: string; to_date?: string }, isReset?: boolean) => {
    isReset && setStatusTag([])
    setFilterTable({
      status: isReset ? [] : (statusTag ?? []),
      from_date: value?.from_date ? (dayjs(value?.from_date).format('YYYY-MM-DD') ?? '') : '',
      to_date: value?.to_date ? (dayjs(value?.to_date).format('YYYY-MM-DD') ?? '') : '',
      certificate: value?.license ?? '',
      speciality: value?.speciality ?? '',
    })
    if (!isReset) {
      setIsDrawerOpen(false)
    }
  }

  const handleSearchLicense = (search: string) => {
    if (search !== '') {
      const filteredData = certificateData?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      setCertificateData(filteredData)
      return
    }
    setCertificateData(certificareOriginData)
  }

  const handleSearchSpeciality = (search: string) => {
    if (search !== '') {
      const filteredData = specialityData?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      setSpecialityData(filteredData)
      return
    }
    setSpecialityData(specialityOriginData)
  }

  const handleStatusChange = (selectedValue: any[]) => {
    setStatusTag(selectedValue)
  }

  const handleReset = () => {
    form.resetFields()
    form.setFieldValue('from_date', '')
    form.setFieldValue('to_date', '')
    setStatusTag([])
    setFilterTable({
      status: [],
      from_date: '',
      to_date: '',
      certificate: '',
      speciality: '',
    })
  }

  const filterOptions = (
    <div className="h-full">
      <Form
        form={form}
        onFinish={(value) => {
          setFilterApply(true)
          applyFilter(value)
        }}
        className="h-full flex flex-col justify-between filters"
      >
        <div className="flex flex-col gap-2">
          <CustomMultiSelect name="status" handleChange={handleStatusChange} Options={statusOption} placeholder={SHIFTS.STATUS} optionWidth={200} value={statusTag} />
          <CustomSelect
            label={COMMON.LICENSE}
            options={certificateData?.map((item: { name: string; id: string }) => ({
              label: item?.name,
              value: item?.id,
            }))}
            value={getValue('license')}
            isSearch
            searchPlaceholder={COMMON.SEARCH_LICENCE}
            name="license"
            searchData={handleSearchLicense}
            wrapperClass="mt-6"
          />
          <CustomSelect
            label={COMMON.SPECIALITY}
            options={specialityData?.map((item: { name: string; id: string }) => ({
              label: item?.name,
              value: item?.id,
            }))}
            value={getValue('speciality')}
            isSearch
            searchPlaceholder={COMMON.SEARCH_SPECIALITY}
            name="speciality"
            searchData={handleSearchSpeciality}
          />
          <CustomDatePickerField
            name="from_date"
            placeholder={SHIFTS.FORM_DATE}
            icon={<DatePickerIcon />}
            form={form}
            value={form.getFieldValue('from_date')}
            wrapperClass="mb-0 show-form-error"
            rules={[
              {
                // eslint-disable-next-line
                validator: () => {
                  const toDate = form?.getFieldValue('to_date')
                  const fromDate = form?.getFieldValue('from_date')
                  if (toDate && !fromDate) {
                    return Promise.reject(new Error(VALIDATION_ERROR.DATE))
                  }
                  return Promise.resolve()
                },
              },
            ]}
          />

          <CustomDatePickerField
            name="to_date"
            placeholder={SHIFTS.TO_DATE}
            icon={<DatePickerIcon />}
            form={form}
            value={form.getFieldValue('to_date')}
            wrapperClass="show-form-error"
            rules={[
              {
                // eslint-disable-next-line
                validator: () => {
                  const fromDate = form?.getFieldValue('from_date')
                  const toDate = form?.getFieldValue('to_date')
                  if (!toDate && fromDate) {
                    return Promise.reject(new Error(VALIDATION_ERROR.DATE))
                  } else if (fromDate && toDate && dayjs(fromDate)?.isAfter(dayjs(toDate))) {
                    return Promise.reject(new Error(VALIDATION_ERROR.DATE_RANGE))
                  }
                  return Promise.resolve()
                },
              },
            ]}
          />
        </div>
        <div className="flex gap-2">
          <CustomButton
            onClick={() => {
              applyFilter({}, true)
              setFilterApply(true)
            }}
            text={BUTTON_STRING.RESET}
            className="bg-primary-50 text-primary-600 border-0 h-49 w-160 reset-btn-hover"
            htmlType="reset"
            type="default"
          />
          <CustomButton text={BUTTON_STRING.APPLY} htmlType="submit" className="h-49 w-160" />
        </div>
      </Form>
    </div>
  )

  return (
    <div>
      <CustomTable
        option={tabData}
        isTab
        isFilter
        isSearch
        searchPlaceholder={COMMON.SEARCH_SHIFT}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
        tableButton={tableButton}
        column={columns}
        setModalOpen={handleDetailModal}
        data={shiftData}
        total={total}
        current={current}
        limit={limit}
        onChangePage={handleChangePage}
        className="shift-table row-selected"
        onChange={handleTableChange}
        name={SHIFTS.SHIFTS}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        onSearch={onSearch}
        showFilterDrawer={isDrawerOpen}
        filterOption={filterOptions}
        filterSelected={filterTable}
        onDrawerOpenChange={handleDrawerOpenChange}
        handleReset={handleReset}
      />

      {isDetailModalOpen && (
        <DetailShift
          setTabId={setTabId}
          tabId={tabId}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isCancel={isCancel}
          setIsCreateShiftModalOpen={setIsCreateShiftModalOpen}
          setIsCancel={setIsCancel}
          id={rowId}
          setRowId={setRowId}
          isDetailShiftOpen={isDetailModalOpen}
          setIsDetailShiftOpen={setIsDetailModalOpen}
        />
      )}
      {isOpenCancelModal && (
        <CustomModal
          onCancel={() => {
            setIsCancelModal(false)
          }}
          footer={null}
          closable={false}
          width={600}
          open={isOpenCancelModal}
          title={SHIFTS.CANCEL_SHIFT}
          content={
            <CancelShift id={rowId} setId={setRowId} isCancel={isCancel} setIsCancel={setIsCancel} isDetailShiftOpen={isDetailModalOpen} setIsModalOpen={setIsCancelModal} />
          }
        />
      )}
      {isCreateShiftModalOpen && (
        <CreateShift
          rowId={rowId}
          setRowId={setRowId}
          isCreateShiftModalOpen={isCreateShiftModalOpen}
          setIsCreateShiftModalOpen={setIsCreateShiftModalOpen}
          setSortTable={setSortTable}
        />
      )}
    </div>
  )
}

export default Shifts
