import React from 'react'

const MapsIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="url(#paint0_linear_7294_244590)" />
      <path
        d="M33.5 18.5H30.5V14.5C30.5 13.9 30.1 13.5 29.5 13.5H18.5C17.9 13.5 17.5 13.9 17.5 14.5V18.5H14.5C13.9 18.5 13.5 18.9 13.5 19.5V33.5C13.5 34.1 13.9 34.5 14.5 34.5H33.5C34.1 34.5 34.5 34.1 34.5 33.5V19.5C34.5 18.9 34.1 18.5 33.5 18.5ZM19.5 30.5H18.5C17.9 30.5 17.5 30.1 17.5 29.5C17.5 28.9 17.9 28.5 18.5 28.5H19.5C20.1 28.5 20.5 28.9 20.5 29.5C20.5 30.1 20.1 30.5 19.5 30.5ZM19.5 26.5H18.5C17.9 26.5 17.5 26.1 17.5 25.5C17.5 24.9 17.9 24.5 18.5 24.5H19.5C20.1 24.5 20.5 24.9 20.5 25.5C20.5 26.1 20.1 26.5 19.5 26.5ZM24.5 30.5H23.5C22.9 30.5 22.5 30.1 22.5 29.5C22.5 28.9 22.9 28.5 23.5 28.5H24.5C25.1 28.5 25.5 28.9 25.5 29.5C25.5 30.1 25.1 30.5 24.5 30.5ZM24.5 26.5H23.5C22.9 26.5 22.5 26.1 22.5 25.5C22.5 24.9 22.9 24.5 23.5 24.5H24.5C25.1 24.5 25.5 24.9 25.5 25.5C25.5 26.1 25.1 26.5 24.5 26.5ZM25.5 21H25V21.5C25 22.1 24.6 22.5 24 22.5C23.4 22.5 23 22.1 23 21.5V21H22.5C21.9 21 21.5 20.6 21.5 20C21.5 19.4 21.9 19 22.5 19H23V18.5C23 17.9 23.4 17.5 24 17.5C24.6 17.5 25 17.9 25 18.5V19H25.5C26.1 19 26.5 19.4 26.5 20C26.5 20.6 26.1 21 25.5 21ZM29.5 30.5H28.5C27.9 30.5 27.5 30.1 27.5 29.5C27.5 28.9 27.9 28.5 28.5 28.5H29.5C30.1 28.5 30.5 28.9 30.5 29.5C30.5 30.1 30.1 30.5 29.5 30.5ZM29.5 26.5H28.5C27.9 26.5 27.5 26.1 27.5 25.5C27.5 24.9 27.9 24.5 28.5 24.5H29.5C30.1 24.5 30.5 24.9 30.5 25.5C30.5 26.1 30.1 26.5 29.5 26.5Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_7294_244590" x1="-8.4" y1="-8.4" x2="40.8" y2="51" gradientUnits="userSpaceOnUse">
          <stop stopColor="#220EB1" />
          <stop offset="1" stopColor="#6111B9" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default MapsIcon
