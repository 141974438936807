import { useState } from 'react'
import { Checkbox, Form, Image, Table } from 'antd'
import { type CheckboxChangeEvent } from 'antd/es/checkbox'
import { type ColumnsType } from 'antd/es/table'
import { profile } from 'assets/imgs'
import { HospitalOutline } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import CustomSelect from 'component/Select/CustomSelect'
import { BUTTON_STRING, TABLE_COLUMNS } from 'constants/Constants'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { dropDownValidations, floorValidation } from 'utils/Validations'

const performance = [
  {
    label: 'Performance',
    value: '1',
  },
]
const service = [
  {
    label: 'service',
    value: '1',
  },
]

const floor = [
  {
    value: '1',
    label: '1st Floor',
  },
]

const AddEvaluation = ({ setIsModalOpen }: any) => {
  const [tableData, setTableData] = useState([
    {
      id: '1',
      performanceEvaluation: 'Clinical Competence',
      oftenExceedExp: false,
      mostExp: false,
      doesNotMeetExp: false,
      na: false,
    },
    {
      id: '2',
      performanceEvaluation: 'doesNotMeetExp',
      oftenExceedExp: true,
      mostExp: false,
      doesNotMeetExp: false,
      na: false,
    },
    {
      id: '3',
      performanceEvaluation: 'Attendance & Punctuality',
      oftenExceedExp: true,
      mostExp: false,
      doesNotMeetExp: false,
      na: false,
    },
    {
      id: '4',
      performanceEvaluation: 'Good Communication Skills',
      oftenExceedExp: true,
      mostExp: false,
      doesNotMeetExp: false,
      na: false,
    },
  ])
  const [error, setError] = useState({
    service: '',
    performance: '',
    preferred: '',
    floor: '',
  })
  const [form] = Form.useForm()

  const onCancel = () => {
    setIsModalOpen(false)
  }

  const onChange = (e: CheckboxChangeEvent, record: { id: any }) => {
    const { id } = record
    const dataIndex = e.target.name
    const checked = e.target.checked

    const updatedTableData = tableData.map((row) => {
      if (row.id === id) {
        return { ...row, [dataIndex as keyof typeof row]: checked }
      }
      return row
    })

    setTableData(updatedTableData)
  }

  const onFinish = (values: any) => {
    const tableValues = tableData.map((row) => ({
      performanceEvaluation: row.performanceEvaluation,
      oftenExceedExp: row.oftenExceedExp,
      mostExp: row.mostExp,
      doesNotMeetExp: row.doesNotMeetExp,
      na: row.na,
    }))
    console.log({ values, tableValues })
  }

  const columns: ColumnsType = [
    {
      title: TABLE_COLUMNS.COMPLETE_NEW_EVALUATION.PERFORMANCE,
      dataIndex: 'performanceEvaluation',
      key: 'performanceEvaluation',
      align: 'center',
      width: 230,
    },
    {
      title: TABLE_COLUMNS.COMPLETE_NEW_EVALUATION.EXCEED,
      dataIndex: 'oftenExceedExp',
      key: 'oftenExceedExp',
      align: 'center',
      width: 230,
      render(value, record: any) {
        return (
          <Checkbox
            name="oftenExceedExp"
            onChange={(e) => {
              onChange(e, record)
            }}
            rootClassName="table-check-box"
          />
        )
      },
    },
    {
      title: TABLE_COLUMNS.COMPLETE_NEW_EVALUATION.EXPECTATION,
      dataIndex: 'mostExp',
      key: 'mostExp',
      align: 'center',
      width: 230,
      render(value, record: any) {
        return (
          <Checkbox
            name="mostExp"
            onChange={(e) => {
              onChange(e, record)
            }}
            rootClassName="table-check-box"
          />
        )
      },
    },
    {
      title: TABLE_COLUMNS.COMPLETE_NEW_EVALUATION.NOT_EXPECTED,
      dataIndex: 'doesNotMeetExp',
      key: 'doesNotMeetExp',
      align: 'center',
      width: 230,
      render(value, record: any) {
        return (
          <Checkbox
            name="doesNotMeetExp"
            onChange={(e) => {
              onChange(e, record)
            }}
            rootClassName="table-check-box"
          />
        )
      },
    },
    {
      title: TABLE_COLUMNS.COMPLETE_NEW_EVALUATION.NA,
      dataIndex: 'na',
      key: 'na',
      align: 'center',
      width: 80,
      render(value, record: any) {
        return (
          <Checkbox
            name="na"
            onChange={(e) => {
              onChange(e, record)
            }}
            rootClassName="table-check-box"
          />
        )
      },
    },
  ]

  return (
    <Form
      className="add-evaluation-container"
      form={form}
      onFinish={onFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <div className="add-evaluation">
        <div className="evaluation-detail-wrapper mt-6px">
          <div className="provider-detail-box">
            <Image src={profile} preview={false} className="evaluation-img" />
            <p className="text-neutral-800 mobile-heading-h6  font-normal">Brooklyn Simmons</p>
          </div>
          <div className="flex flex-col w-full relative">
            <div className={`cursor-pointer  w-full flex flex-col`}>
              <CustomSelect name="service" rules={dropDownValidations} options={service} label="Service Periode" error={error.service} />
            </div>
          </div>
          <div className="flex flex-col w-full relative">
            <div className={`cursor-pointer  w-full flex flex-col `}>
              <CustomSelect name="performance" rules={dropDownValidations} options={performance} label="Performance Evaluation" error={error.performance} />
            </div>
          </div>
          <div className={`facility-flor-wrapper `}>
            <div className="facility-wrapper w-full">
              <HospitalOutline />
              <p className="text-neutral-700 para-p2 font-normal">Autumn healthcare & Habitation</p>
            </div>
            <div className="flex w-full">
              <CustomSelect name="floor" rules={floorValidation} options={floor} label="Floor" error={error.floor} />
            </div>
          </div>
        </div>
        <div className="add-evaluation-table-wrapper">
          <Table columns={columns} dataSource={tableData} rootClassName="complete-evaluation-table" />
        </div>
      </div>
      <div className="footer-wrapper">
        <div className="percentage-wrapper">
          <p className="text-primary-600 desktop-head-h5 font-semi-bold">
            0% <span className="text-primary-600 para-p2 font-medium">(0 out of 400)</span>
          </p>
        </div>
        <div className="flex gap-4">
          <CustomButton type="default" text={BUTTON_STRING.CANCEL} className="w-166 h-49" onClick={onCancel} />
          <CustomButton text={BUTTON_STRING.SAVE_EVALUATION} type="primary" htmlType="submit" className="w-166 h-49" />
        </div>
      </div>
    </Form>
  )
}

export default AddEvaluation
