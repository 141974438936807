import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, message } from 'antd'
import { LogoIcon } from 'assets/svgs'
import { UsaIcon } from 'assets/svgs/index'
import CustomButton from 'component/Button/CustomButton'
import InputField from 'component/InputField/InputField'
import PasswordField from 'component/PasswordField/PasswordField'
import { COMMON, SIGNUP } from 'constants/Constants'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { passwordValidations } from 'utils/Validations'

import { getProfileFacilityUser, updateProfileFacilityUser } from './api'
import { type IFacilityUserSignUp } from './types'

const FacilityUserSignUp = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const [formData, setFormData] = useState<IFacilityUserSignUp>()
  const [error, setError] = useState({
    first_name: '',
    last_name: '',
    user_name: '',
    email: '',
    country_code: '',
    mobile_no: '',
    password: '',
  })
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id') ?? ''
  const countryCode = SIGNUP.COUNTRY_CODE

  useEffect(() => {
    void getProfileData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    form.setFieldsValue(formData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  const onFinish = (value: IFacilityUserSignUp) => {
    const payload = {
      first_name: formData?.first_name,
      user_name: formData?.user_name,
      email: formData?.email,
      last_name: formData?.last_name,
      country_code: countryCode,
      mobile_no: formData?.mobile_no,
      password: value?.password,
    }
    void updateProfileData(payload)
  }

  const getProfileData = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getProfileFacilityUser(id)
    if (response?.data?.statusCode === 1) {
      setFormData(response?.data?.data)
    } else {
      navigate('/link-expiry')
    }
    dispatch(setGlobalLoader(false))
  }

  const updateProfileData = async (data: IFacilityUserSignUp) => {
    dispatch(setGlobalLoader(true))
    const response = await updateProfileFacilityUser(data, id)
    if (response?.data?.statusCode === 1) {
      void message.success(response.data?.message)
      navigate('/login')
    } else {
      void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <div className="bg-neutral-50 screen-height">
      <div className="bg-neutral-50 mx-auto max-w-400 w-full flex flex-col gap-10 pt-140 items-center">
        <div className="flex gap-6 flex-col items-center">
          <LogoIcon />
          <div className="flex flex-col items-center gap-1">
            <p className="desktop-heading-h3 font-medium">
              {SIGNUP.WELCOME} {formData?.first_name}!
            </p>
            <p className="text-neutral-500 text-center para-p2">{SIGNUP.FACILITY_SIGNUP_SUB}</p>
          </div>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={formData}
          requiredMark={false}
          layout="vertical"
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
        >
          <div>
            <div className="w-full">
              <div className={`flex edit-name gap-6 h-64  ${error.last_name || error.first_name ? '' : 'mb-6'} `}>
                <div className="flex ">
                  <InputField placeholder={COMMON.FIRST_NAME} disabled name="first_name" inputClass={'login-input hover-100'} value={formData?.first_name ?? ''} />
                </div>
                <div className="flex ">
                  <InputField placeholder={COMMON.LAST_NAME} disabled inputClass={'login-input hover-100'} name="last_name" value={formData?.last_name ?? ''} />
                </div>
              </div>

              {(error.last_name || error.first_name) && (
                <div className="flex gap-6 edit-name">
                  <div className="flex items-center w-50">
                    <p className="error-container mb-6">{error.first_name}</p>
                  </div>
                  <div className="flex items-center w-50">
                    <p className="error-container mb-6">{error.last_name}</p>
                  </div>
                </div>
              )}
            </div>

            <InputField disabled name="email" placeholder={COMMON.EMAIL} error={error.email} value={formData?.email ?? ''} />

            <div className="flex gap-6">
              <div className="country-code-wrapper w-120 h-64">
                <div className="country-flag">
                  <UsaIcon />
                </div>
                <p className="para-p2 font-normal text-neutral-800 ">{countryCode}</p>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex flex-col flex-grow-1">
                  <div className={`w-full flex-grow-1 `}>
                    <InputField name="mobile_no" disabled placeholder={COMMON.MOBILE_NO} error={error.mobile_no} value={formData?.mobile_no ?? ''} />
                  </div>
                </div>
              </div>
            </div>

            <PasswordField error={error.password} name="password" rules={passwordValidations} placeholder={COMMON.SET_STRONG_PASS} inputClass={'login-input password-input'} />

            <Form.Item className="m-0">
              <CustomButton text={COMMON.CONTINUE} type="primary" htmlType="submit" />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default FacilityUserSignUp
