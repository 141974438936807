import type { Rule } from 'antd/es/form'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'

export const emailValidations = [
  { required: true, message: VALIDATION_ERROR.COMMON.EMAIL },
  {
    type: 'email',
    message: VALIDATION_ERROR.COMMON.EMAIL2,
  },
]

export const passwordValidations: Rule[] = [
  { required: true, message: VALIDATION_ERROR.PASSWORD.EMPTY_NEW_PASSWORD },
  ({ getFieldValue }) => ({
    async validator(_, value) {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/

      if (value) {
        if (value.length < 8) {
          return await Promise.reject(new Error(VALIDATION_ERROR.PASSWORD.MINIMUM_LENGTH))
        }
        if (!passwordRegex.test(value)) {
          return await Promise.reject(new Error(VALIDATION_ERROR.PASSWORD.INVALID_PASSWORD))
        }
      }

      await Promise.resolve()
    },
  }),
]

export const loginPasswordValidations: Rule[] = [{ required: true, message: VALIDATION_ERROR.COMMON.PASSWORD }]

export const commonSelectFieldValidation = (fieldName: string) => [
  {
    required: true,
    message: `${VALIDATION_ERROR.SELECT_ONE} ${fieldName}`,
  },
]

export const commonMultiSelectValidation = (fieldName: string) => [
  {
    required: true,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    message: `${VALIDATION_ERROR.MULTI_SELECT} ${fieldName}!`,
  },
]

export const newPwdValidation: Rule[] = [
  { required: true, message: VALIDATION_ERROR.PASSWORD.EMPTY_NEW_PASSWORD },
  ({ getFieldValue }) => ({
    async validator(_, value) {
      const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).*$/

      if (value) {
        if (value.length < 8) {
          return await Promise.reject(new Error(VALIDATION_ERROR.PASSWORD.MINIMUM_LENGTH))
        }
        if (!passwordRegex.test(value)) {
          return await Promise.reject(new Error(VALIDATION_ERROR.PASSWORD.INVALID_PASSWORD))
        }
      }

      await Promise.resolve()
    },
  }),
]

export const confirmPwdValidation: Rule[] = [
  { required: true, message: VALIDATION_ERROR.PASSWORD.EMPTY_CONFIRM_PASSWORD },
  ({ getFieldValue }) => ({
    async validator(_, value) {
      if (value) {
        const newPassword = getFieldValue('newPassword')
        const password = getFieldValue('password')
        if (newPassword && newPassword !== value) {
          return await Promise.reject(new Error(VALIDATION_ERROR.PASSWORD.NOT_MATCH_PASSWORD))
        }
        if (password && password !== value) {
          return await Promise.reject(new Error(VALIDATION_ERROR.PASSWORD.NOT_MATCH_PASSWORD))
        }
      }
      await Promise.resolve()
    },
  }),
]

export const mobileValidations = [
  { required: true, message: VALIDATION_ERROR.COMMON.MOBILE },
  { pattern: /^[0-9+\s]+$/, message: VALIDATION_ERROR.COMMON.MOBILE2 },
  { min: 8, message: VALIDATION_ERROR.COMMON.MINNUMBER },
  { max: 14, message: VALIDATION_ERROR.COMMON.MAXNUMBER },
]

export const commonInputFieldValidation = (fieldName: string) => [
  {
    required: true,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    message: `${VALIDATION_ERROR.INPUT_FIELD} ${fieldName}`,
  },
]

export const commonSelectValidation = (fieldName: string) => [
  {
    required: true,
    message: `${VALIDATION_ERROR.SINGLE_SELECT} ${fieldName}`,
  },
]

export const zipCodeValidation = (fieldName: string) => [
  {
    required: true,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    message: `${VALIDATION_ERROR.INPUT_FIELD} ${fieldName}`,
  },
  { pattern: /(^\d{5}$)|(^\d{5}-\d{4}$)/, message: 'Please enter valid zip code!' },
]

export const dnrDescriptionValidation = [{ required: true, message: VALIDATION_ERROR.COMMON.DESCRIPTION }]

export const dropDownValidations = [{ required: true, message: VALIDATION_ERROR.SELECT_ONE }]

export const floorValidation = [{ required: true, message: VALIDATION_ERROR.COMMON.FLOOR }]

export const reviewValidation = [{ required: true, message: VALIDATION_ERROR.COMMON.REVIEW }]
