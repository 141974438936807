import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import HeadingText from 'component/HeadingText'
import { USER_PROFILE_STRING } from 'constants/Constants'

interface SignaturePadProps {
  height: number
  width: number
  penColor?: string
  onEnd?: () => void
  hideRedrawBtn?: boolean
  canDraw: boolean
  setCanDraw: React.Dispatch<React.SetStateAction<boolean>>
  clearSignature?: () => void
}

// eslint-disable-next-line react/display-name
const SignaturePad = forwardRef<HTMLCanvasElement, SignaturePadProps>(
  ({ height, width, penColor = 'black', onEnd, hideRedrawBtn = false, canDraw, setCanDraw, clearSignature }, ref) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const contextRef = useRef<CanvasRenderingContext2D | null>(null)
    const [isDrawing, setIsDrawing] = useState(false)
    const [lastPoint, setLastPoint] = useState<{ x: number; y: number } | null>(null)

    useEffect(() => {
      const canvas = canvasRef.current
      if (canvas) {
        canvas.width = width
        canvas.height = height
        const context = canvas.getContext('2d')
        if (context) {
          context.lineCap = 'round'
          context.strokeStyle = penColor
          context.lineWidth = 2
          contextRef.current = context
        }
      }
    }, [width, height, penColor])

    const startDrawing = ({ nativeEvent }: React.MouseEvent | React.TouchEvent | any) => {
      if (!canDraw) return // Prevent drawing if canDraw is false
      const { offsetX, offsetY } = nativeEvent
      contextRef.current?.beginPath()
      contextRef.current?.moveTo(offsetX, offsetY)
      setIsDrawing(true)
      setLastPoint({ x: offsetX, y: offsetY })
    }

    const finishDrawing = () => {
      if (!isDrawing) return
      contextRef.current?.closePath()
      setIsDrawing(false)
      setLastPoint(null)
      if (onEnd) onEnd()
    }

    const draw = ({ nativeEvent }: React.MouseEvent | React.TouchEvent | any) => {
      if (!isDrawing || !lastPoint) return

      const { offsetX, offsetY } = nativeEvent
      const context = contextRef.current

      if (context) {
        context.beginPath()
        context.moveTo(lastPoint.x, lastPoint.y)
        context.lineTo(offsetX, offsetY)
        context.stroke()
        context.closePath()
        setLastPoint({ x: offsetX, y: offsetY })
      }
    }

    const clearCanvas = () => {
      if (setCanDraw) setCanDraw(true)
      const canvas = canvasRef.current
      if (canvas) {
        contextRef.current?.clearRect(0, 0, canvas.width, canvas.height)
        if (clearSignature) {
          clearSignature()
        }
      }
    }

    // Expose canvasRef through the ref prop
    useImperativeHandle(ref, () => canvasRef.current as HTMLCanvasElement)

    return (
      <div className="flex flex-col">
        <canvas
          ref={canvasRef}
          height={height}
          width={width}
          onMouseDown={startDrawing}
          onMouseUp={finishDrawing}
          onMouseMove={draw}
          onMouseLeave={finishDrawing}
          onTouchStart={startDrawing}
          onTouchEnd={finishDrawing}
          onTouchMove={draw}
        />
        {!hideRedrawBtn && <HeadingText text={USER_PROFILE_STRING.RE_DRAW_SIGNATURE} classString="text-neutral-700 para-p3 cursor-pointer w-full" onClick={clearCanvas} />}
      </div>
    )
  }
)

export default SignaturePad
